import ActionTypes from 'constants/ActionTypes';

/**
 * Redux action creators for persistent UI changes
 */

export const setDefaults = newDefaults => ({
    type: ActionTypes.UI_DEFAULTS_ALL_SET,
    error: false,
    payload: {
        keyword: newDefaults.keyword,
        location: newDefaults.location,
        platform: newDefaults.platform,
    },
});

export const setDefaultKeyword = keyword => ({
    type: ActionTypes.UI_DEFAULTS_KEYWORD_SET,
    error: false,
    payload: keyword,
});

export const setDefaultPlatformById = id => ({
    type: ActionTypes.UI_DEFAULTS_PLATFORM_ID_SET,
    error: false,
    payload: id,
});

export const setDefaultLocation = location => ({
    type: ActionTypes.UI_DEFAULTS_LOCATION_SET,
    error: false,
    payload: location,
});

export const setMetricsSelected = payload => ({
    type: ActionTypes.UI_DEFAULTS_SELECTED_METRICS_SET,
    error: false,
    payload,
});
