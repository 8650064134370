import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    currentPageNumber: 0,
    export: {
        fetching: false,
        error: {
            status: null,
            text: null,
        },
    },
};

const snapshotReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_CURRENT_SNAPSHOT_PAGE_SET_RECEIVED: {
            return update(state, {
                currentPageNumber: { $set: action.payload },
            });
        }
        case ActionTypes.DATA_RESULTS_RECEIVED: {
            return update(state, {
                currentPageNumber: { $set: 1 },
            });
        }
        case ActionTypes.DATA_RESULTS_MORE_RECEIVED: {
            return update(state, {
                currentPageNumber: {
                    $set: action.payload.pageNumber + 1,
                },
            });
        }
        case ActionTypes.DATA_SNAPSHOT_IMAGE_REQUESTED: {
            return update(state, {
                export: { fetching: { $set: true } },
            });
        }
        case ActionTypes.DATA_SNAPSHOT_IMAGE_FINISHED: {
            return update(state, {
                export: { fetching: { $set: false } },
            });
        }
        case ActionTypes.DATA_SNAPSHOT_IMAGE_ERROR: {
            return update(state, {
                export: {
                    fetching: { $set: false },
                    error: {
                        status: { $set: action.payload.status },
                        text: { $set: action.payload.text },
                    },
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default snapshotReducer;
