import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    keyword: null,
    locationId: null,
    platformId: null,
};

const paramsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_PARAMS_SET: {
            return update(state, {
                keyword: { $set: action.payload.keyword },
                locationId: { $set: action.payload.locationId },
                platformId: { $set: action.payload.platformId },
            });
        }
        default: {
            return state;
        }
    }
};

export default paramsReducer;
