import CSV from 'comma-separated-values';
import { concat, props, isNil, clone, keys, pipe, reduce } from 'ramda';
import CSVExportService from 'mangools-commons/lib/services/CSVExportService';

import { FEATURE_TYPES } from 'constants/SerpFeatureTypes';
import { FEATURES } from 'constants/SerpFeatures';

const BASIC_FIELDS = ['title', 'url'];
const BASIC_METRICS = ['linkProfileStrength'];

class ExportResultsService {
    static exportGlobalMetrics(globalMetrics = null) {
        if (isNil(globalMetrics)) {
            return null;
        } else {
            return CSV.encode(
                [[globalMetrics.seoCompetition, globalMetrics.serpFeaturesImpact, globalMetrics.resultCount]],
                {
                    header: ['Keyword SEO Difficulty', 'SERP Features Impact', 'Number of Results'],
                },
            );
        }
    }

    static exportSerpFeature(serpFeatureType, rowLength) {
        // NOTE adding 1 because basic fields and metric fields don't include position
        const rowSize = 1 + rowLength;
        const row = Array(rowSize).fill('');
        row[1] = `[SERP FEATURE]: ${FEATURES[serpFeatureType].name}`;
        return row;
    }

    static formatMetric(metrics) {
        return (metricsAcc, metricKey) => {
            const metric = metrics[metricKey];

            return {
                ...metricsAcc,
                [metricKey]: CSVExportService.formatEmptyValue(metric),
            };
        };
    }

    static formatMetrics(result) {
        const formatMetric = ExportResultsService.formatMetric(result.metrics);

        const metrics = pipe(keys, reduce(formatMetric, {}))(result.metrics);

        return {
            ...result,
            metrics,
        };
    }

    static export({ results, selectedMetricFields, globalMetrics, includeSerpFeatures = false }) {
        let organicResultsPositionMap = null;
        const metricFields = concat(BASIC_METRICS, selectedMetricFields);
        const filteredResults = includeSerpFeatures
            ? results
            : results.filter(result => result.type === FEATURE_TYPES.ORGANIC);

        if (includeSerpFeatures) {
            organicResultsPositionMap = results
                .filter(result => result.type === FEATURE_TYPES.ORGANIC)
                .reduce((positionMap, result, index) => {
                    const newPositionMap = {
                        ...positionMap,
                        [result.url]: index + 1,
                    };
                    return newPositionMap;
                }, {});
        }

        const resultData = filteredResults.map((result, index) => {
            if (result.type !== FEATURE_TYPES.ORGANIC) {
                return ExportResultsService.exportSerpFeature(result.type, metricFields.length + BASIC_FIELDS.length);
            } else {
                const clonedResult = pipe(clone, ExportResultsService.formatMetrics)(result);

                const position = includeSerpFeatures ? organicResultsPositionMap[clonedResult.url] : index + 1;

                const basicValues = concat([position], props(BASIC_FIELDS, clonedResult));
                const metricValues = props(metricFields, clonedResult.metrics);

                return concat(basicValues, metricValues);
            }
        });

        const basicFields = concat(['position'], BASIC_FIELDS);
        const globalMetricsCSV = ExportResultsService.exportGlobalMetrics(globalMetrics);
        const exportCSV = CSV.encode(resultData, { header: concat(basicFields, metricFields) });

        if (!isNil(globalMetricsCSV)) {
            return `${globalMetricsCSV}\n \n${exportCSV}`;
        } else {
            return exportCSV;
        }
    }

    static exportMetrics({ result, selectedMetricFields }) {
        const metricFields = concat(BASIC_METRICS, selectedMetricFields);

        if (result.metrics) {
            return metricFields.map(field => `${field}: ${result.metrics[field]}`).join(', ');
        } else {
            return metricFields.map(field => `${field}: ${result[field]}`).join(', ');
        }
    }
}

export default ExportResultsService;
