import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';
import concat from 'ramda/src/concat';
import append from 'ramda/src/append';

const initialState = {
    id: '',
    ctr: [],
    data: [],
    error: {
        status: null,
        text: null,
    },
    fetching: false,
    fetchingMore: false,
    nextPage: 0,
    pageBreakpoints: [],
    resultCount: 0,
    seoCompetition: 0,
    seoCompetitionUpdatedAt: 0,
    serpFeaturesImpact: 0,
    updatedAt: 0,
    serpSnapshotIds: [],
};

const resultReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_RESULTS_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_RESULTS_RECEIVED: {
            return update(state, {
                id: { $set: action.payload.id },
                ctr: { $set: action.payload.ctr },
                data: { $set: action.payload.results },
                error: {
                    status: { $set: initialState.error.status },
                    text: { $set: initialState.error.text },
                },
                fetching: { $set: false },
                nextPage: { $set: 1 },
                pageBreakpoints: { $set: [action.payload.results.length] },
                resultCount: { $set: action.payload.resultCount },
                seoCompetition: { $set: action.payload.seoCompetition },
                seoCompetitionUpdatedAt: { $set: action.payload.seoCompetitionUpdatedAt },
                serpFeaturesImpact: { $set: action.payload.serpFeaturesImpact },
                updatedAt: { $set: action.payload.updatedAt },
                serpSnapshotIds: { $set: [action.payload.serpSnapshotId] },
            });
        }
        case ActionTypes.DATA_RESULTS_RESET_RECEIVED: {
            return update(state, {
                id: { $set: action.payload.id },
                ctr: { $set: action.payload.ctr },
                data: { $set: action.payload.results },
                error: {
                    status: { $set: initialState.error.status },
                    text: { $set: initialState.error.text },
                },
                fetching: { $set: false },
                nextPage: { $set: action.payload.pageBreakpoints?.length + 1 },
                pageBreakpoints: { $set: action.payload.pageBreakpoints },
                resultCount: { $set: action.payload.resultCount },
                seoCompetition: { $set: action.payload.seoCompetition },
                seoCompetitionUpdatedAt: { $set: action.payload.seoCompetitionUpdatedAt },
                serpFeaturesImpact: { $set: action.payload.serpFeaturesImpact },
                updatedAt: { $set: action.payload.updatedAt },
                serpSnapshotIds: { $set: [action.payload.serpSnapshotId] },
            });
        }
        case ActionTypes.DATA_RESULTS_EMPTY_RECEIVED: {
            return update(state, {
                fetching: { $set: false },
            });
        }
        case ActionTypes.DATA_RESULTS_MORE_EMPTY_RECEIVED: {
            return update(state, {
                fetchingMore: { $set: false },
            });
        }
        case ActionTypes.DATA_RESULTS_ERROR: {
            return update(state, {
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_RESULTS_MORE_FETCHING: {
            return update(state, {
                fetchingMore: { $set: true },
            });
        }
        case ActionTypes.DATA_RESULTS_MORE_RECEIVED: {
            return update(state, {
                serpSnapshotIds: { $apply: data => append(action.payload.serpData.serpSnapshotId, data) },
                ctr: { $apply: data => concat(data, action.payload.serpData.ctr) },
                fetchingMore: { $set: false },
                nextPage: { $apply: nextPage => nextPage + 1 },
                data: { $apply: data => concat(data, action.payload.serpData.results) },
                pageBreakpoints: {
                    $apply: breakpoints =>
                        concat(breakpoints, [state.data.length + action.payload.serpData.results.length]),
                },
            });
        }
        case ActionTypes.DATA_RESULTS_MORE_ERROR: {
            return update(state, {
                fetchingMore: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default resultReducer;
