import { number, oneOfType, shape, string } from 'prop-types';
import NoValueType from 'types/NoValueType';

export default shape({
    id: string.isRequired,
    kdUpdatedAt: oneOfType([number, NoValueType]).isRequired,
    keyword: string.isRequired,
    keywordDifficulty: oneOfType([number, NoValueType]).isRequired,
    keywordId: string.isRequired,
    location: shape({
        id: number.isRequired,
        label: string.isRequired,
    }).isRequired,
    platformId: number.isRequired,
});
