import { createSelector } from 'reselect';
import Defaults from 'mangools-commons/lib/constants/Defaults';
import DateTimeService from 'mangools-commons/lib/services/DateTimeService';
import isNil from 'ramda/src/isNil';
import UserPlanTypes from 'constants/UserPlanTypes';

export const accessTokenSelector = state => state.user.accessToken;
export const createdAtSelector = state => state.user.createdAt;

export const fetchedSelector = state => state.user.fetched;
export const idSelector = state => state.user.id;
export const limitsFetchedSelector = state => state.user.limits.fetched;
export const loggedInSelector = state => state.user.loggedIn;
export const ssoTicketSelector = state => state.user.ssoTicket;
export const userPlanSelector = state => state.user.plan;
export const loginTokenSelector = state => state.user.loginToken;
export const unleashSessionSelector = state => state.user.unleashSession;
export const isSubUserSelector = state => state.user.isSubUser;

export const userPlanTypeSelector = createSelector([loggedInSelector, userPlanSelector], (loggedIn, userPlan) => {
    if (loggedIn === false || isNil(userPlan)) {
        return UserPlanTypes.NO_PLAN;
    } else {
        switch (userPlan) {
            case 'free': {
                return UserPlanTypes.FREE;
            }
            case 'trial': {
                return UserPlanTypes.TRIAL;
            }
            case 'entry': {
                return UserPlanTypes.ENTRY;
            }
            case 'basic': {
                return UserPlanTypes.BASIC;
            }
            case 'premium': {
                return UserPlanTypes.PREMIUM;
            }
            case 'agency': {
                return UserPlanTypes.AGENCY;
            }
            case 'custom': {
                return UserPlanTypes.CUSTOM;
            }
            default: {
                // NOTE: Should never happen
                return UserPlanTypes.NO_PLAN;
            }
        }
    }
});

export const timerLimitSecondsSelector = createSelector(
    [loggedInSelector, createdAtSelector, userPlanTypeSelector],
    (loggedIn, createdAt, userPlanType) => {
        // TODO: remove after debugging
        // return 15 * 60;

        // Only trial user can see this subscribe message, every new user will be TRIAL user
        if (loggedIn && !isNil(createdAt) && userPlanType === UserPlanTypes.TRIAL) {
            return DateTimeService.getTimeDifferenceInSeconds(
                createdAt + Defaults.USER_FRESH_LIMIT_MINUTES * 60 * 1000,
            );
        } else {
            return 0;
        }
    },
);

export const newUserMessageVisibilitySelector = createSelector(
    [loggedInSelector, createdAtSelector, userPlanTypeSelector],
    (loggedIn, createdAt, userPlanType) => {
        // TODO: remove after debugging
        // return true;

        // Only trial user can see this subscribe message, every new user will be TRIAL user
        if (loggedIn && !isNil(createdAt) && userPlanType === UserPlanTypes.TRIAL) {
            return DateTimeService.lessThanMinutesAgo(Defaults.USER_FRESH_LIMIT_MINUTES, createdAt);
        } else {
            return false;
        }
    },
);
