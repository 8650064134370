import { createSelector } from 'reselect';
import Platforms, { MOBILE } from 'mangools-commons/lib/constants/Platforms';
import find from 'ramda/src/find';
import propEq from 'ramda/src/propEq';
import isNil from 'ramda/src/isNil';

export const platformSelector = state => state.params.platformId;
export const keywordSelector = state => state.params.keyword;
export const locationIdSelector = state => state.params.locationId;

export const isMobilePlatformSelector = createSelector(platformSelector, id => {
    const platform = find(propEq('id', id))(Platforms);

    if (isNil(platform)) {
        return false;
    } else {
        return platform.label === MOBILE.label;
    }
});

export const paramsSelector = state => state.params;
