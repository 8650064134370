// const PARSED_DATA = [{
//     createdAt: 1450276391,
//     id: '567176272a0225a07cbbc66e',
//     kdUpdatedAt: 15300023,
//     keyword: 'flights to london',
//     keywordDifficulty: 32,
//     keywordId: '782da73af646e66cde0a43e1d5be7c41',
//     location: {
//         id: 2703,
//         label: 'Slovakia'
//     },
//     platformId: 0,
// }];

import reverse from 'ramda/src/reverse';
import { parseIntWithNVFallback, parseLocationLabel } from 'sources/parsers/commons';

const parseLocation = rawLocation => ({
    id: parseInt(rawLocation._id, 10), // eslint-disable-line no-underscore-dangle
    label: parseLocationLabel(rawLocation.label),
});

export const parse = rawData =>
    reverse(
        rawData.map(item => ({
            id: item._id, // eslint-disable-line no-underscore-dangle
            kdUpdatedAt: parseIntWithNVFallback(item.rank_ts),
            keyword: item.kw,
            keywordDifficulty: parseIntWithNVFallback(item.rank),
            keywordId: item._id, // eslint-disable-line no-underscore-dangle
            location: parseLocation(item.location),
            platformId: parseInt(item.platform_id, 10),
        })),
    );
