import React from 'react';
import { arrayOf, bool, func, number, string } from 'prop-types';
import { connect } from 'react-redux';

import MetricsModal from 'components/modals/metrics/MetricsModal';

import { closeMetricsModal, setMetricFilter } from 'actions/uiActions';

import { setMetricsSelected } from 'actions/defaultsActions';

import { metricsModalVisibilitySelector } from 'selectors/uiSelectors';

import MetricFilterService from 'services/MetricFilterService';

function ModalContainer(props) {
    return (
        <div className="modals">
            {props.metricsModalVisible ? (
                <MetricsModal
                    metricFilter={props.metricFilter}
                    metrics={MetricFilterService.filter(props.metricFilter)}
                    onCloseModal={props.onCloseMetricsModal}
                    onMetricFilterChange={props.onMetricFilterChange}
                    onMetricSelectChange={props.onMetricSelectChange}
                    selectedMetricIds={props.selectedMetricIds}
                />
            ) : null}
        </div>
    );
}

const mapStateToProps = state => ({
    metricFilter: state.ui.modals.metrics.filter,
    metricsModalVisible: metricsModalVisibilitySelector(state),
    selectedMetricIds: state.ui.defaults.selectedMetricIds,
});

const mapDispatchToProps = (dispatch, _ownProps) => ({
    onCloseMetricsModal() {
        dispatch(closeMetricsModal());
    },
    onMetricFilterChange(newFilter) {
        dispatch(setMetricFilter(newFilter));
    },
    onMetricSelectChange(ids) {
        dispatch(setMetricsSelected(ids));
    },
});

ModalContainer.propTypes = {
    metricFilter: string.isRequired,
    metricsModalVisible: bool.isRequired,
    onCloseMetricsModal: func.isRequired,
    onMetricFilterChange: func.isRequired,
    onMetricSelectChange: func.isRequired,
    selectedMetricIds: arrayOf(number).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
