import { node, number, shape, string } from 'prop-types';

export default shape({
    columnName: string.isRequired,
    description: string.isRequired,
    id: number.isRequired,
    name: string.isRequired,
    propertyName: string.isRequired,
    tooltipContent: node.isRequired,
    type: string.isRequired,
    valueType: string.isRequired,
});
