/* eslint-disable max-len */

// const PARSED_DATA = {
//     serpSourceUrl: 'https://s3.amazonaws.com/mangools-google-pages/7c907deef4780fc1bc92346a45615457.2017-04-11.html',
//     resultCount: 23000,
//     seoCompetition: 78,
//     results: [{
//         metrics: {
//             acRank: 5,
//             authorityLinks: 12000,
//             citationFlow: 29,
//             domainAuthority: 12.34545,
//             externalBackLinks: 2557,
//             externalBackLinksEdu: 0,
//             externalBackLinksEduExact: 0,
//             externalBackLinksGov: 0,
//             externalBackLinksGovExact: 0,
//             externalLinks: 132222,
//             externalLinksToRoot: 1933,
//             externalLinksToSubdomain: 203,
//             facebookShares: 8911,
//             indexedURLs: 1,
//             linkProfileStrength: 54,
//             linkingCBlocks: 1200,
//             links: 1200444,
//             linksToRoot: 2300,
//             linksToSubdomain: 2333,
//             mozRank: 29.11,
//             mozRankExternalEquity: 23.33,
//             mozRankRoot: 21.00,
//             mozRankRootCombined: 24.44,
//             mozRankRootExternalEquity: 28.99,
//             mozRankSubdomain: 22.11,
//             mozRankSubdomainCombined: 20.12,
//             mozRankSubdomainExternalEquity: 29.33,
//             mozTrust: 23.11,
//             mozTrustRoot: 11.22,
//             mozTrustSubdomain: 20.33,
//             outDomainsExternal: 9,
//             outLinksExternal: 9,
//             outLinksInternal: 14,
//             pageAuthority: 28.10,
//             refDomains: 54,
//             refDomainsEdu: 0,
//             refDomainsEduExact: 0,
//             refDomainsGov: 0,
//             refDomainsGovExact: 0,
//             refIPs: 50,
//             refSubNets: 37,
//             rootDomainRootDomainsLinking: 23000,
//             rootDomainsLinking: 29123,
//             rootDomainsLinkingToSubdomain: 2388,
//             spamScoreSubdomain: 23,
//             subdomainExternalLinks: 2300,
//             subdomainLinking: 1000,
//             subdomainLinkingToSubdomain: 2900,
//             trustFlow: 17,
//         },
//         title: 'What is SEO...'
//         url: 'http://seotools.com'
//     }]
// };

import Defaults from 'mangools-commons/lib/constants/Defaults';
import isNil from 'ramda/src/isNil';
import isEmpty from 'ramda/src/isEmpty';

import MetricPropertyNames from 'constants/MetricPropertyNames';
import { FEATURE_TYPES, SUB_FEATURE_TYPES } from 'constants/SerpFeatureTypes';
import { decodeUrl, parseIntWithFallback, parseFloatWithFallback, parseLocationLabel } from 'sources/parsers/commons';
import Other from 'constants/Other';
import SerpFeatureService from 'services/SerpFeatureService';

const isMajesticPresent = item => !isNil(item.m.majestic);
const isMozPresent = item => !isNil(item.m.moz);
const isFacebookPresent = item => !isNil(item.m.fb);
const isRankPresent = item => !isNil(item.m.rank);

// Converting raw type from API to more generalized type used in the app
const convertType = type => {
    switch (type) {
        case 'CALCULATOR':
        case 'CURRENCY':
        case 'DICTIONARY':
        case 'MEASURE_UNIT':
        case 'PROGRAM_OVERVIEW':
        case 'QUANTITY_ANSWER':
        case 'SPORTS':
        case 'TIME':
        case 'TOP_SIGHTS':
        case 'TRANSLATOR':
        case 'TRAVEL_BOX':
        case 'WEATHER': {
            return FEATURE_TYPES.ANSWER_BOX;
        }
        case 'KNO_GRAPH_LINK': {
            return FEATURE_TYPES.KNO_GRAPH;
        }
        case 'NEWS_CAROUSEL': {
            return FEATURE_TYPES.TOP_STORIES;
        }
        case 'NEWS_PACK': {
            return FEATURE_TYPES.TOP_STORIES_ROW;
        }
        case 'VIDEO_PANEL': {
            return FEATURE_TYPES.VIDEO_BIG;
        }
        case 'LOCAL_PACK': {
            return FEATURE_TYPES.MAP_PACK;
        }
        case 'POPULAR_LIST': {
            return FEATURE_TYPES.TOP_CAROUSEL;
        }
        case 'INSTALL_APP_GRID': {
            return FEATURE_TYPES.INSTALL_APP_CAROUSEL;
        }
        case 'INDEPTH_ARTICLE': {
            return FEATURE_TYPES.INDEPTH_ARTICLES;
        }
        default: {
            return type;
        }
    }
};

const convertSubType = type => {
    switch (type) {
        case 'GALLERY_IMAGE':
        case 'GALLERY_CAROUSEL': {
            return SUB_FEATURE_TYPES.IMAGE_GALLERY;
        }
        case 'SIMILAR_LINKS': {
            return SUB_FEATURE_TYPES.SITELINKS;
        }
        case 'EVENT': {
            return SUB_FEATURE_TYPES.EVENT_LIST;
        }
        case 'THUMBNAIL_IMG': {
            return SUB_FEATURE_TYPES.IMAGE_THUMBNAIL;
        }
        case 'THUMBNAIL_VIDEO': {
            return SUB_FEATURE_TYPES.VIDEO_THUMBNAIL;
        }
        case 'BREADCRUMB': {
            return SUB_FEATURE_TYPES.BREADCRUMBS;
        }
        case 'ADS_SECTION': {
            return SUB_FEATURE_TYPES.AD_FEATURE;
        }
        case 'SIMILAR_RESULT': {
            return SUB_FEATURE_TYPES.SIMILAR_RESULTS;
        }
        case 'NEWS_CAROUSEL': {
            return SUB_FEATURE_TYPES.TOP_STORIES_FROM_WEBSITE;
        }
        default: {
            return type;
        }
    }
};

const parseItemMetrics = item => ({
    linkProfileStrength: isRankPresent(item) ? parseIntWithFallback(item.m.rank.v.r) : Defaults.NO_VALUE,
    [MetricPropertyNames.acRank]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.ACRank)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.topRank]: !isNil(item.topRank) ? parseInt(item.topRank, 10) : Other.MAX_TOP_RANK,
    [MetricPropertyNames.authorityLinks]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.ueid)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.citationFlow]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.CitationFlow)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.domainAuthority]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.pda)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinksEduExact]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.ExtBackLinksEDU_Exact)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinksEdu]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.ExtBackLinksEDU)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinksGovExact]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.ExtBackLinksGOV_Exact)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinksGov]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.ExtBackLinksGOV)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinks]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.ExtBackLinks)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalLinks]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.ued)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalLinksToRoot]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.ped)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalLinksToSubdomain]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.fed)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.facebookShares]: isFacebookPresent(item)
        ? parseIntWithFallback(item.m.fb.v.l)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.indexedURLs]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.IndexedURLs)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.linkingCBlocks]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.pib)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.links]: isMozPresent(item) ? parseIntWithFallback(item.m.moz.v.uid) : Defaults.NO_VALUE,
    [MetricPropertyNames.linksToRoot]: isMozPresent(item) ? parseIntWithFallback(item.m.moz.v.puid) : Defaults.NO_VALUE,
    [MetricPropertyNames.linksToSubdomain]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.fuid)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankExternalEquity]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.uemrp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRank]: isMozPresent(item) ? parseFloatWithFallback(item.m.moz.v.umrp) : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankRootCombined]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.fjp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankRootExternalEquity]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.pejp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankRoot]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.pmrp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankSubdomainCombined]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.pjp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankSubdomainExternalEquity]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.fejp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankSubdomain]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.fmrp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozTrust]: isMozPresent(item) ? parseFloatWithFallback(item.m.moz.v.utrp) : Defaults.NO_VALUE,
    [MetricPropertyNames.mozTrustRoot]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.ptrp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozTrustSubdomain]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.ftrp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.outDomainsExternal]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.OutDomainsExternal)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.outLinksExternal]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.OutLinksExternal)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.outLinksInternal]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.OutLinksInternal)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.pageAuthority]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.upa)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.refDomainsEduExact]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.RefDomainsEDU_Exact)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.refDomainsEdu]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.RefDomainsEDU)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.refDomainsGovExact]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.RefDomainsGOV_Exact)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.refDomainsGov]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.RefDomainsGOV)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.refDomains]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.RefDomains)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.refIPs]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.RefIPs)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.refSubNets]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.RefSubNets)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.rootDomainRootDomainsLinking]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.pid)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.rootDomainsLinking]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.uipl)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.rootDomainsLinkingToSubdomain]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.fipl)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.spamScoreSubdomain]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.fspsc)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.subdomainExternalLinks]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.feid)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.subdomainLinking]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.uifq)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.subdomainLinkingToSubdomain]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.fid)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.trustFlow]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.TrustFlow)
        : Defaults.NO_VALUE,
});

const parsePosition = rawPosition => ({
    bottom: parseInt(rawPosition.b, 10),
    left: parseInt(rawPosition.l, 10),
    right: parseInt(rawPosition.r, 10),
    top: parseInt(rawPosition.t, 10),
});

const parseSubItems = subItems =>
    subItems.map(item => ({
        count: !isNil(item.items) ? item.items.length : Defaults.NOT_AVAILABLE,
        type: convertSubType(item.type),
    }));

export const parseItem = item => {
    const type = convertType(item.type);
    const isOrganicOrFeatured = SerpFeatureService.isOrganicOrFeatured(type);
    const defaultPosition = { bottom: 0, left: 0, right: 0, top: 0 };

    return {
        domain: isOrganicOrFeatured ? item.domain : Defaults.NOT_AVAILABLE,
        inViewPercent: !isNil(item.visPart) ? parseInt(item.visPart, 10) : Defaults.NOT_AVAILABLE,
        metrics: isOrganicOrFeatured ? parseItemMetrics(item) : null,
        position: !isNil(item.size) ? parsePosition(item.size) : defaultPosition,
        prerender: item.prerender || false,
        subItems: !isNil(item.items) ? parseSubItems(item.items) : [],
        title: isOrganicOrFeatured ? item.title : Defaults.NOT_AVAILABLE,
        ctr: item.ctr,
        type,
        url: isOrganicOrFeatured ? decodeUrl(item.url) : Defaults.NOT_AVAILABLE,
    };
};

export const parse = rawData => ({
    id: rawData._id, // eslint-disable-line no-underscore-dangle
    ctr: rawData.ctr,
    location: {
        id: parseInt(rawData.location._id, 10), // eslint-disable-line no-underscore-dangle
        label: parseLocationLabel(rawData.location.label),
        code: rawData.location.code,
    },
    resultCount: parseIntWithFallback(rawData.results),
    results: rawData.items.map(item => parseItem(item)),
    seoCompetition: parseIntWithFallback(rawData.rank),
    seoCompetitionUpdatedAt: parseIntWithFallback(rawData.rank_updated_at),
    serpFeaturesImpact: parseInt(rawData.serpFeaturesImpact, 10),
    updatedAt: parseInt(rawData.created_at, 10), // NOTE: Should be called updated_at in API response...
    serpSnapshotId: rawData.serp_snapshot_id || '',
});

export const parseResetData = rawSerpsData => {
    const firstSerpData = rawSerpsData[0];

    return {
        id: firstSerpData._id, // eslint-disable-line no-underscore-dangle
        ctr: firstSerpData.ctr,
        location: {
            id: parseInt(firstSerpData.location._id, 10), // eslint-disable-line no-underscore-dangle
            label: parseLocationLabel(firstSerpData.location.label),
            code: firstSerpData.location.code,
        },
        resultCount: parseIntWithFallback(firstSerpData.results),
        results: rawSerpsData.reduce((items, serp) => [...items, ...serp.items.map(parseItem)], []),
        pageBreakpoints: rawSerpsData.reduce((breakpoints, serp) => {
            const lastBreakpoint = isEmpty(breakpoints) ? 0 : breakpoints[breakpoints.length - 1];

            return [...breakpoints, lastBreakpoint + serp.items.length];
        }, []),
        seoCompetition: parseIntWithFallback(firstSerpData.rank),
        seoCompetitionUpdatedAt: parseIntWithFallback(firstSerpData.rank_updated_at),
        serpFeaturesImpact: parseInt(firstSerpData.serpFeaturesImpact, 10),
        updatedAt: parseInt(firstSerpData.created_at, 10), // NOTE: Should be called updated_at in API response...
        serpSnapshotId: firstSerpData.serp_snapshot_id || '',
    };
};
