import ActionTypes from 'constants/ActionTypes';
import update from 'immutability-helper';

const initialState = {
    exporting: false,
};

const exportReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_EXPORT_RESULTS_EXPORTING: {
            return update(state, {
                exporting: { $set: true },
            });
        }
        case ActionTypes.DATA_EXPORT_RESULTS_FINISHED: {
            return update(state, {
                exporting: { $set: false },
            });
        }
        case ActionTypes.DATA_EXPORT_RESULTS_ERROR:
        case ActionTypes.UI_MESSAGES_EXPORT_SHOW: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default exportReducer;
