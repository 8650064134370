import ActionTypes from 'constants/ActionTypes';

/**
 * Redux action creators for UI changes
 */

export const closeAll = () => ({
    type: ActionTypes.UI_ALL_CLOSE,
});

export const showMetricsModal = () => ({
    type: ActionTypes.UI_METRICS_MODAL_SHOW,
});

export const closeMetricsModal = () => ({
    type: ActionTypes.UI_METRICS_MODAL_CLOSE,
});

export const showFailureMessage = ({ details, header }) => ({
    type: ActionTypes.UI_MESSAGES_FAILURE_SHOW,
    error: false,
    payload: { details, header },
});

export const closeFailureMessage = () => ({
    type: ActionTypes.UI_MESSAGES_FAILURE_CLOSE,
});

export const showAccessDeniedMessage = () => ({
    type: ActionTypes.UI_MESSAGES_ACCESS_DENIED_SHOW,
});

export const closeAccessDeniedMessage = () => ({
    type: ActionTypes.UI_MESSAGES_ACCESS_DENIED_CLOSE,
});

export const setMetricFilter = newFilter => ({
    type: ActionTypes.UI_METRICS_FILTER_SET,
    error: false,
    payload: newFilter,
});

export const showExportMessage = () => ({
    type: ActionTypes.UI_MESSAGES_EXPORT_SHOW,
});

export const closeExportMessage = () => ({
    type: ActionTypes.UI_MESSAGES_EXPORT_CLOSE,
});

export const showLongerLoadingNotification = () => ({
    type: ActionTypes.UI_MISC_LONGER_LOADING_NOTIFICATION_SHOW,
});

export const hideLongerLoadingNotification = () => ({
    type: ActionTypes.UI_MISC_LONGER_LOADING_NOTIFICATION_HIDE,
});

export const receivedErrorNotificationAction = (message, config) => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_ERROR_SHOW,
    error: false,
    payload: { message, config },
});

export const receivedInfoNotificationAction = (message, config) => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_INFO_SHOW,
    error: false,
    payload: { message, config },
});

export const requestedCloseAllNotificationsAction = () => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_CLOSE_ALL,
});

export const closeHelpDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_HELP_CLOSE,
});

export const showHelpDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_HELP_SHOW,
});

export const closeTopLevelAnnouncementMessage = () => ({
    type: ActionTypes.UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE,
});

export const setOnlineStatus = ({ onlineStatus }) => ({
    type: ActionTypes.UI_MISC_ONLINE_STATUS_SET,
    error: false,
    payload: {
        onlineStatus,
    },
});

export const showNoConnectionMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NO_CONNECTION_SHOW,
});

export const closeNoConnectionMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NO_CONNECTION_CLOSE,
});

export const showPricingMessage = () => ({
    type: ActionTypes.UI_MESSAGES_PRICING_SHOW,
});

export const closePricingMessage = () => ({
    type: ActionTypes.UI_MESSAGES_PRICING_CLOSE,
});

export const showNeedToSignInMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_SHOW,
});

export const closeNeedToSignInMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE,
});

export const requestedResultAndComparingTablesHorizontalScrollSync = ({ horizontalPosition, source }) => ({
    type: ActionTypes.UI_MISC_RESULT_AND_COMPARING_TABLES_HORIZONTAL_SCROLL_SYNC_REQUESTED,
    error: false,
    payload: {
        horizontalPosition,
        source,
    },
});

export const setComparingBoxCurrentView = view => ({
    type: ActionTypes.UI_COMPARING_BOX_CURRENT_VIEW_SET,
    error: false,
    payload: view,
});

export const setComparingBoxUrl = url => ({
    type: ActionTypes.UI_COMPARING_BOX_URL_SET,
    error: false,
    payload: url,
});

export const setComparingBoxDeltaY = deltaY => ({
    type: ActionTypes.UI_COMPARING_BOX_DELTA_Y_SET,
    error: false,
    payload: deltaY,
});

export const setComparingBoxScrollLeft = scrollLeft => ({
    type: ActionTypes.UI_COMPARING_BOX_SCROLL_LEFT_SET,
    error: false,
    payload: scrollLeft,
});

export const setComparingBoxUrlProtocol = protocol => ({
    type: ActionTypes.UI_COMPARING_BOX_URL_PROTOCOL_SET,
    error: false,
    payload: protocol,
});

export const showActionsDropdown = id => ({
    type: ActionTypes.UI_DROPDOWNS_ACTIONS_SHOW,
    error: false,
    payload: id,
});

export const closeActionsDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_ACTIONS_CLOSE,
});

export const showLoggedOutMessage = () => ({
    type: ActionTypes.UI_MESSAGES_LOGGED_OUT_SHOW,
});

export const closeLoggedOutMessage = () => ({
    type: ActionTypes.UI_MESSAGES_LOGGED_OUT_CLOSE,
});

export const showKeywordDifficultyInfoMessage = () => ({
    type: ActionTypes.UI_MESSAGES_KD_INFO_SHOW,
});

export const closeKeywordDifficultyInfoMessage = () => ({
    type: ActionTypes.UI_MESSAGES_KD_INFO_CLOSE,
});

export const showSerpFeatureInfoMessage = ({ featureType, subFeature }) => ({
    type: ActionTypes.UI_MESSAGES_SERP_FEATURE_INFO_SHOW,
    error: false,
    payload: {
        featureType,
        subFeature,
    },
});

export const closeSerpFeatureInfoMessage = () => ({
    type: ActionTypes.UI_MESSAGES_SERP_FEATURE_INFO_CLOSE,
});

export const setNewVersionNotificationShown = () => ({
    type: ActionTypes.UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET,
});

export const showSerpFeaturesImpactInfoMessage = () => ({
    type: ActionTypes.UI_MESSAGES_SERP_FEATURES_IMPACT_INFO_SHOW,
});

export const closeSerpFeaturesImpactInfoMessage = () => ({
    type: ActionTypes.UI_MESSAGES_SERP_FEATURES_IMPACT_INFO_CLOSE,
});

export const showAppPanel = () => ({
    type: ActionTypes.UI_PANELS_APP_SHOW,
});

export const closeAppPanel = () => ({
    type: ActionTypes.UI_PANELS_APP_CLOSE,
});

export const showHistoryPanel = () => ({
    type: ActionTypes.UI_PANELS_HISTORY_SHOW,
});

export const closeHistoryPanel = () => ({
    type: ActionTypes.UI_PANELS_HISTORY_CLOSE,
});

export const setDashboardTab = payload => ({
    type: ActionTypes.UI_MISC_DASHBOARD_TAB_SET,
    payload,
});

export const setColorScheme = payload => ({
    type: ActionTypes.UI_MISC_COLOR_SCHEME_SET,
    payload,
});

export const showShortcutsMessage = () => ({
    type: ActionTypes.UI_MESSAGES_SHORTCUTS_SHOW,
});

export const closeShortcutsMessage = () => ({
    type: ActionTypes.UI_MESSAGES_SHORTCUTS_CLOSE,
});
