import { createSelector } from 'reselect';

import { serpSnapshotIdsSelector } from 'selectors/resultsSelectors';

export const currentPageNumberSelector = state => state.snapshot.currentPageNumber;
export const fetchingSnapshotImageSelector = state => state.snapshot.export.fetching;

export const loadedPageCountSelector = createSelector(
    serpSnapshotIdsSelector,
    serpSnapshotIds => serpSnapshotIds.length,
);

export const firstPageSerpSnapshotIdSelector = createSelector(
    [serpSnapshotIdsSelector],
    serpSnapshotIds => serpSnapshotIds[0] || '',
);

export const currentSerpSnapshotIdSelector = createSelector(
    [serpSnapshotIdsSelector, currentPageNumberSelector],
    (serpSnapshotIds, pageNumber) => serpSnapshotIds[pageNumber - 1] || '',
);
