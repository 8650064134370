import isNil from 'ramda/src/isNil';
import { FEATURES, SUB_FEATURES } from 'constants/SerpFeatures';
import { FEATURE_TYPES } from 'constants/SerpFeatureTypes';
import { FEED, RIGHT } from 'constants/SerpFeaturePositions';

const ORGANIC_LEFT_POS = 166;

class SerpFeatureService {
    static getFeatureObj(featureType) {
        const feature = FEATURES[featureType];

        if (!isNil(feature)) {
            return feature;
        } else {
            return null;
        }
    }

    static getSubFeatureObj(featureType) {
        const feature = SUB_FEATURES[featureType];

        if (!isNil(feature)) {
            return feature;
        } else {
            return null;
        }
    }

    static isOrganic(featureType) {
        return featureType === FEATURE_TYPES.ORGANIC;
    }

    static isFeatured(featureType) {
        return featureType === FEATURE_TYPES.FEATURED_SNIPPET;
    }

    static isOrganicOrFeatured(featureType) {
        return this.isOrganic(featureType) || this.isFeatured(featureType);
    }

    static getHorizontalPosition(positionObj) {
        if (!isNil(positionObj)) {
            if (positionObj.left > ORGANIC_LEFT_POS) {
                return RIGHT;
            } else {
                return FEED;
            }
        } else {
            return null;
        }
    }
}

export default SerpFeatureService;
