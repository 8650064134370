import Metrics from 'constants/Metrics';
import is from 'ramda/src/is';
import isEmpty from 'ramda/src/isEmpty';
import filter from 'ramda/src/filter';
import toLower from 'ramda/src/toLower';
import trim from 'ramda/src/trim';

/**
 * Metric filter service.
 */
class MetricFilterService {
    /**
     * Gets metrics which pass the provided filter.
     *
     * @param {rawTerm: string} Filter term.
     * @return {[ Metric ]} Array of passed Metrics (filtered by matching term in name and description).
     */
    static filter(rawTerm) {
        if (is(String, rawTerm) && !isEmpty(rawTerm)) {
            const term = toLower(trim(rawTerm));

            return filter(
                metric => toLower(metric.name).includes(term) || toLower(metric.description).includes(term),
                Metrics,
            );
        } else {
            return Metrics;
        }
    }
}

export default MetricFilterService;
