import React, { Component } from 'react';
import { node, string } from 'prop-types';
import ReactTooltip from 'rc-tooltip';
import classnames from 'classnames';
import shallowCompare from 'react-addons-shallow-compare';

class Tooltip extends Component {
    shouldComponentUpdate(newProps, newState) {
        return shallowCompare(this, newProps, newState);
    }

    render() {
        return (
            <ReactTooltip
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                overlay={this.props.overlayContent}
                overlayClassName={classnames('animated-once-fast', 'fade-in', this.props.overlayClassName)}
                placement={this.props.placement}
                prefixCls="tooltip"
            >
                {this.props.children}
            </ReactTooltip>
        );
    }
}

Tooltip.propTypes = {
    children: node.isRequired,
    overlayClassName: string,
    overlayContent: node.isRequired,
    placement: string,
};

Tooltip.defaultProps = {
    overlayClassName: null,
    placement: 'top',
};

export default Tooltip;
