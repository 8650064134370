import { createSelector } from 'reselect';
import includes from 'ramda/src/includes';
import isNil from 'ramda/src/isNil';
import Metrics from 'constants/Metrics';

const selectedMetricIdsSelector = state => state.ui.defaults.selectedMetricIds;

export const historyPanelVisibilitySelector = state => state.ui.panels.history.visibility;
export const appPanelVisibilitySelector = state => state.ui.panels.app.visibility;

const accessDeniedMessageVisiblitySelector = state => state.ui.messages.accessDenied.visibility;
export const exportMessageVisiblitySelector = state => state.ui.messages.export.visibility;
const keywordDifficultyInfoMessageVisiblitySelector = state => state.ui.messages.keywordDifficultyInfo.visibility;
const loggedOutMessageVisiblitySelector = state => state.ui.messages.loggedOut.visibility;
const noConnectionMessageVisiblitySelector = state => state.ui.messages.noConnection.visibility;
const serpFeaturesImpactInfoMessageVisiblitySelector = state => state.ui.messages.serpFeaturesImpactInfo.visibility;
const serpFeatureInfoMessageVisiblitySelector = state => state.ui.messages.serpFeatureInfo.visibility;
const failureMessageVisibilitySelector = state => state.ui.messages.failure.visibility;
const needToSignInMessageVisibilitySelector = state => state.ui.messages.needToSignIn.visibility;
export const shortcutsMessageVisibilitySelector = state => state.ui.messages.shortcuts.visibility;

// TODO: Pricing message visible when need to sign in is not
export const pricingMessageVisibilitySelector = state =>
    state.ui.messages.pricing.visibility && !needToSignInMessageVisibilitySelector(state);

export const selectedMetricsSelector = createSelector(
    selectedMetricIdsSelector,
    // Return metrics for selected ids
    ids => Metrics.filter(metric => includes(metric.id, ids)),
);

export const selectedMetricFieldsSelector = createSelector(
    selectedMetricIdsSelector,
    // Return metric fields for selected ids
    ids => Metrics.filter(metric => includes(metric.id, ids)).map(metric => metric.propertyName),
);

// Modal
export const metricsModalVisibilitySelector = state => state.ui.modals.metrics.visibility;

export const anyModalVisibleSelector = createSelector(
    [metricsModalVisibilitySelector],
    metricsModalVisibility => metricsModalVisibility,
);

// Dropdowns
export const actionsDropdownVisibleIdSelector = state => state.ui.dropdowns.actions.visibleId;
export const helpDropdownVisibilitySelector = state => state.ui.dropdowns.help.visibility;
export const userDropdownVisibilitySelector = state => state.ui.dropdowns.user.visibility;

// Returns true if any of the dropdowns is visible
export const anyDropdownVisibleSelector = createSelector(
    [actionsDropdownVisibleIdSelector, helpDropdownVisibilitySelector, userDropdownVisibilitySelector],
    (actionsDropdownVisibleId, helpDropdownVisible) => !isNil(actionsDropdownVisibleId) || helpDropdownVisible,
);

// Misc
export const newVersionNotificationShownSelector = state => state.ui.misc.newVersionNotificationShown;
export const onlineStatusSelector = state => state.ui.misc.onlineStatus;
export const dashboardTabSelector = state => state.ui.misc.dashboardTab;
export const colorSchemeSelector = state => state.ui.misc.colorScheme;

export const mobileOverlayVisibilitySelector = createSelector(
    [anyDropdownVisibleSelector],
    anyDropdownVisible => anyDropdownVisible,
);

export const overlayVisibilitySelector = createSelector(
    [appPanelVisibilitySelector, historyPanelVisibilitySelector, failureMessageVisibilitySelector],
    (appVisibility, historyVisibility, failure) => !failure && (appVisibility || historyVisibility),
);

// Comparing box
export const comparingBoxCurrentViewSelector = state => state.ui.comparingBox.currentView;
export const comparingBoxDeltaYSelector = state => state.ui.comparingBox.deltaY;
export const comparingBoxScrollLeftSelector = state => state.ui.comparingBox.scrollLeft;
export const comparingBoxUrlSelector = state => state.ui.comparingBox.url;
export const comparingBoxUrlProtocolSelector = state => state.ui.comparingBox.urlProtocol;

export const anyPanelVisibleSelector = createSelector(
    [appPanelVisibilitySelector, historyPanelVisibilitySelector],
    (appPanelVisible, historyPanelVisible) => appPanelVisible || historyPanelVisible,
);

export const anyMessageVisibleSelector = createSelector(
    [
        accessDeniedMessageVisiblitySelector,
        exportMessageVisiblitySelector,
        keywordDifficultyInfoMessageVisiblitySelector,
        loggedOutMessageVisiblitySelector,
        noConnectionMessageVisiblitySelector,
        serpFeaturesImpactInfoMessageVisiblitySelector,
        serpFeatureInfoMessageVisiblitySelector,
        failureMessageVisibilitySelector,
        needToSignInMessageVisibilitySelector,
        shortcutsMessageVisibilitySelector,
    ],
    (
        accessDeniedMessageVisible,
        exportMessageVisible,
        keywordDifficultyInfoMessageVisible,
        loggedOutMessageVisible,
        noConnectionMessageVisible,
        serpFeaturesImpactInfoMessageVisible,
        serpFeatureInfoMessageVisible,
        failureMessageVisible,
        needToSignInMessageVisible,
        shortcutsMessageVisible,
    ) =>
        accessDeniedMessageVisible ||
        exportMessageVisible ||
        keywordDifficultyInfoMessageVisible ||
        loggedOutMessageVisible ||
        noConnectionMessageVisible ||
        serpFeaturesImpactInfoMessageVisible ||
        serpFeatureInfoMessageVisible ||
        failureMessageVisible ||
        needToSignInMessageVisible ||
        shortcutsMessageVisible,
);

export const anyOverlayVisibleSelector = createSelector(
    [anyMessageVisibleSelector, anyModalVisibleSelector, anyPanelVisibleSelector],
    (anyMessageVisible, anyModalVisible, anyPanelVisible) => anyPanelVisible || anyModalVisible || anyMessageVisible,
);
