import ActionTypes from 'constants/ActionTypes';

/**
 * Redux action creators for data (results, locations)
 * fetching and posting.
 */

export const requestedAction = () => ({
    type: ActionTypes.DATA_RESULTS_REQUESTED,
});

export const requestedUncachedAction = () => ({
    type: ActionTypes.DATA_RESULTS_UNCACHED_REQUESTED,
});

export const fetchingAction = () => ({
    type: ActionTypes.DATA_RESULTS_FETCHING,
});

export const receivedAction = payload => ({
    type: ActionTypes.DATA_RESULTS_RECEIVED,
    error: false,
    payload,
});

export const receivedEmptyAction = () => ({
    type: ActionTypes.DATA_RESULTS_EMPTY_RECEIVED,
});

export const receivedMoreEmptyAction = () => ({
    type: ActionTypes.DATA_RESULTS_MORE_EMPTY_RECEIVED,
});

export const errorAction = payload => ({
    type: ActionTypes.DATA_RESULTS_ERROR,
    error: true,
    payload,
});

export const requestedMoreAction = () => ({
    type: ActionTypes.DATA_RESULTS_MORE_REQUESTED,
});

export const fetchingMoreAction = () => ({
    type: ActionTypes.DATA_RESULTS_MORE_FETCHING,
});

export const receivedMoreAction = (serpData, pageNumber) => ({
    type: ActionTypes.DATA_RESULTS_MORE_RECEIVED,
    error: false,
    payload: {
        serpData,
        pageNumber,
    },
});

export const requestedRefetchAction = () => ({
    type: ActionTypes.DATA_RESULTS_RESET_REQUESTED,
});


export const receivedRefetchAction = payload => ({
    type: ActionTypes.DATA_RESULTS_RESET_RECEIVED,
    error: false,
    payload,
});

export const errorMoreAction = payload => ({
    type: ActionTypes.DATA_RESULTS_MORE_ERROR,
    error: true,
    payload,
});

export const requestedLocationsAction = query => ({
    type: ActionTypes.DATA_LOCATIONS_REQUESTED,
    error: false,
    payload: query,
});

export const fetchingLocationsAction = () => ({
    type: ActionTypes.DATA_LOCATIONS_FETCHING,
});

export const receivedLocationsAction = payload => ({
    type: ActionTypes.DATA_LOCATIONS_RECEIVED,
    error: false,
    payload,
});

export const errorLocationsAction = payload => ({
    type: ActionTypes.DATA_LOCATIONS_ERROR,
    error: true,
    payload,
});

export const setParams = newParams => ({
    type: ActionTypes.DATA_PARAMS_SET,
    error: false,
    payload: {
        keyword: newParams.keyword,
        locationId: newParams.locationId,
        platformId: newParams.platformId,
    },
});

export const requestedCurrentSnapshotPageSetAction = pageNumber => ({
    type: ActionTypes.DATA_CURRENT_SNAPSHOT_PAGE_SET_REQUESTED,
    error: false,
    payload: pageNumber,
});

export const receivedCurrentSnapshotPageSetAction = pageNumber => ({
    type: ActionTypes.DATA_CURRENT_SNAPSHOT_PAGE_SET_RECEIVED,
    error: false,
    payload: pageNumber,
});

export const requestedResultsExportAction = payload => ({
    type: ActionTypes.DATA_EXPORT_RESULTS_REQUESTED,
    payload,
    error: false,
});

export const exportingResultsAction = () => ({
    type: ActionTypes.DATA_EXPORT_RESULTS_EXPORTING,
});

export const exportingResultsFinishedAction = () => ({
    type: ActionTypes.DATA_EXPORT_RESULTS_FINISHED,
});

export const exportingResultsErrorAction = () => ({
    type: ActionTypes.DATA_EXPORT_RESULTS_ERROR,
});

export const rehydrationComplete = () => ({
    type: ActionTypes.DATA_REHYDRATATION_COMPLETE,
});

export const fetchingAnnouncementsAction = () => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_FETCHING,
});

export const receivedAnnouncementsAction = payload => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_RECEIVED,
    error: false,
    payload,
});

export const errorAnnouncementsAction = payload => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_ERROR,
    error: true,
    payload,
});

export const requestedUrlDataAction = () => ({
    type: ActionTypes.DATA_URL_DATA_REQUESTED,
});

export const fetchingUrlDataAction = () => ({
    type: ActionTypes.DATA_URL_DATA_FETCHING,
});

export const receivedUrlDataAction = payload => ({
    type: ActionTypes.DATA_URL_DATA_RECEIVED,
    error: false,
    payload,
});

export const errorUrlDataAction = payload => ({
    type: ActionTypes.DATA_URL_DATA_ERROR,
    error: true,
    payload,
});

export const requestedHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_REQUESTED,
});

export const fetchingHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_FETCHING,
});

export const receivedHistoryAction = payload => ({
    type: ActionTypes.DATA_HISTORY_RECEIVED,
    error: false,
    payload,
});

export const errorHistoryAction = payload => ({
    type: ActionTypes.DATA_HISTORY_ERROR,
    error: true,
    payload,
});

export const skippedHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_SKIPPED,
});

export const receivedDeleteHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_DELETE_RECEIVED,
});

export const requestedDeleteHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_DELETE_REQUESTED,
});

export const errorDeleteHistoryAction = payload => ({
    type: ActionTypes.DATA_HISTORY_DELETE_ERROR,
    error: true,
    payload,
});

export const requestedSnapshotImageAction = (serpSnapshotId, pageNumber) => ({
    type: ActionTypes.DATA_SNAPSHOT_IMAGE_REQUESTED,
    payload: { serpSnapshotId, pageNumber },
});

export const exportingSnapshotImageFinishedAction = () => ({
    type: ActionTypes.DATA_SNAPSHOT_IMAGE_FINISHED,
    error: false,
});

export const errorSnapshotImageAction = payload => ({
    type: ActionTypes.DATA_SNAPSHOT_IMAGE_ERROR,
    error: true,
    payload,
});
