import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    fetching: false,
    error: {
        status: null,
        text: null,
    },
    data: [],
};

const locationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_LOCATIONS_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_LOCATIONS_RECEIVED: {
            return update(state, {
                fetching: { $set: false },
                data: { $set: action.payload },
            });
        }
        case ActionTypes.DATA_LOCATIONS_ERROR: {
            return update(state, {
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default locationReducer;
