import { createSelector } from 'reselect';
import isNil from 'ramda/src/isNil';

import { topLevelAnnouncementMessageVisibilitySelector } from 'selectors/sharedSelectors';

import { limitsFetchedSelector, newUserMessageVisibilitySelector } from 'selectors/userSelectors';

import { ANNOUNCEMENT_EMPTY_MESSAGE } from 'reducers/announcementsReducer';

const announcementsDataSelector = state => state.announcements.data;
const fetchingAnnouncementsDataSelector = state => state.announcements.fetching;

export const announcementsMessageDataSelector = createSelector([announcementsDataSelector], announcementsData => {
    // TODO: Remove after debugging
    // return {
    //     activeFrom: 0,
    //     activeTo: 0,
    //     btnText: 'Read more',
    //     icon: 'rocket',
    //     messageText: 'We\'ve launched the competitor analysis in KWFinder',
    //     newTab: false,
    //     url: 'http://mangools.com/blog'
    // };

    if (isNil(announcementsData)) {
        return ANNOUNCEMENT_EMPTY_MESSAGE;
    } else {
        return announcementsData;
    }
});

export const announcementMesageVisibilitySelector = createSelector(
    [
        fetchingAnnouncementsDataSelector,
        announcementsMessageDataSelector,
        limitsFetchedSelector,
        topLevelAnnouncementMessageVisibilitySelector,
        newUserMessageVisibilitySelector,
    ],
    (fetchingAnnouncements, announcementsMessageData, fetchedLimits, announcementsVisible, newUserMessageVisible) => {
        if (
            fetchedLimits === true &&
            fetchingAnnouncements === false &&
            announcementsVisible === true &&
            newUserMessageVisible === false
        ) {
            // TODO: Remove after debugging
            // return true;

            // There is an announcement which is active
            if (announcementsMessageData.activeTo > Date.now() / 1000) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
);
