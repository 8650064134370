import React from 'react';
import MetricTypes from 'constants/MetricTypes';
import MetricValueTypes from 'constants/MetricValueTypes';
import MetricPropertyNames from 'constants/MetricPropertyNames';

export const DEFAULT_IDS = [
    0, // Domain Authority
    1, // Page Authority
    2, // Citation Flow
    3, // Trust Flow
    // 4, // MozRank
    // 12, // MozTrust
    30, // FB Shares
    31, // Website Popularity Rank
    33, // External BackLinks
    42, // Referring Domains
    47, // Referring IPs
];

/* eslint-disable max-len */
const Metrics = [
    {
        id: 0,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_HUNDRED,
        name: 'Domain Authority',
        columnName: 'DA',
        propertyName: MetricPropertyNames.domainAuthority,
        description: `
        Domain Authority (DA) is a score developed by Moz that predicts how well a website will
        rank on search engine result pages (SERPs). Score ranges from one to 100, with higher
        scores corresponding to a greater ability to rank. It is calculated by evaluating
        linking root domains, number of total links, MozRank, MozTrust, etc. — into a single DA score.
    `,
        tooltipContent: (
            <span>
                <strong>Domain Authority (DA)</strong> — a ranking score by <strong>Moz</strong> that predicts how well
                a website will rank. It is calculated by evaluating linking root domains, number of total links,
                MozRank, MozTrust, etc. — <strong>into a single DA score</strong>.
            </span>
        ),
    },
    {
        id: 1,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_HUNDRED,
        name: 'Page Authority',
        columnName: 'PA',
        propertyName: MetricPropertyNames.pageAuthority,
        description: `
        Page Authority (PA) is a score developed by Moz that predicts how well a specific page will rank.
        Score ranges from one to 100, with higher scores corresponding to a greater ability to rank.
        It is based on data from the Mozscape web index and includes link counts, MozRank, MozTrust,
        and dozens of other factors.
    `,
        tooltipContent: (
            <span>
                <strong>Page Authority (PA)</strong> - a ranking score by <strong>Moz</strong> that predicts how well a
                specific page will rank. Page Authority is based on data from the <strong>Mozscape web index</strong>
                and includes <strong>link counts, MozRank, MozTrust</strong>, and dozens of other factors.
            </span>
        ),
    },
    {
        id: 2,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_HUNDRED,
        name: 'Citation Flow',
        columnName: 'CF',
        propertyName: MetricPropertyNames.citationFlow,
        description: `
        Citation Flow of Domain / Subdomain / URL. It predicts how influential a URL might be based on
        how many sites link to it. Links are now not all created with equal weight - and because a strong
        link will have a relatively stronger influence on URLs, Citation Flow is based on stronger, iterative
        mathematical logic than the old metric of ACRank.
    `,
        tooltipContent: (
            <span>
                <strong>Citation Flow (CF)</strong> - developed by <strong>Majestic</strong>. It predicts how
                influential a URL might be based on <strong>how many sites link to it</strong>.
            </span>
        ),
    },
    {
        id: 3,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_HUNDRED,
        name: 'Trust Flow',
        columnName: 'TF',
        propertyName: MetricPropertyNames.trustFlow,
        description: `
        Trust Flow of Domain / Subdomain / URL. It is a score based on quality, on a scale between 0-100.
        Majestic collated many trusted seed sites based on a manual review of the web. This process forms
        the foundation of Majestic Trust Flow. Sites closely linked to a trusted seed site can see higher
        scores, whereas sites that may have some questionable links would see a much lower score.
    `,
        tooltipContent: (
            <span>
                <strong>Trust Flow (TF)</strong> - developed by <strong>Majestic</strong>, is based on link quality.
                Sites closely <strong>linked to a trusted seed site</strong> can see higher scores, while sites that{' '}
                <strong>have questionable links</strong> would see a lower score.
            </span>
        ),
    },
    {
        id: 4,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_TEN,
        name: 'MozRank: URL',
        columnName: 'MR',
        propertyName: MetricPropertyNames.mozRank,
        description: `
        MozRank quantifies link popularity and is Moz’s version of Google’s PageRank algorithm.
        The higher the MozRank of the linking pages, the higher the MozRank of the page receiving links.
        Similar to how Google’s PageRank was calculated, MozRank is based on a logarithmic scale
        from 0 to 10. It is much easier to improve from a MozRank of 3 to 4 than from 8 to 9.
    `,
        tooltipContent: (
            <span>
                <strong>MozRank (MR)</strong> - developed by <strong>Moz</strong> quantifies link popularity and is
                Moz’s version of Google’s classic PageRank algorithm. MozRank is based on a{' '}
                <strong>logarithmic scale between 0 and 10</strong>.
            </span>
        ),
    },
    {
        id: 5,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_TEN,
        name: 'MozRank: External Equity',
        columnName: 'MR-EE',
        propertyName: MetricPropertyNames.mozRankExternalEquity,
        description: `
        The fraction of the URL's MozRank derived solely from external links, in the normalized 2-point score
    `,
        tooltipContent: (
            <span>
                The fraction of the URL&apos;s MozRank derived solely from external links, in the normalized 2-point
                score
            </span>
        ),
    },
    {
        id: 6,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_TEN,
        name: 'MozRank: Root Domain',
        columnName: 'MR-RD',
        propertyName: MetricPropertyNames.mozRankRoot,
        description: "The MozRank of the URL's root domain, in the normalized 2-point score",
        tooltipContent: <span>The MozRank of the URL&apos;s root domain, in the normalized 2-point score</span>,
    },
    {
        id: 7,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_TEN,
        name: 'MozRank: Root Domain Combined',
        columnName: 'MR-RDC',
        propertyName: MetricPropertyNames.mozRankRootCombined,
        description: 'The combined MozRank of all pages on the subdomain, in the normalized 2-point score',
        tooltipContent: (
            <span>The combined MozRank of all pages on the subdomain, in the normalized 2-point score</span>
        ),
    },
    {
        id: 8,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_TEN,
        name: 'MozRank: Root Domain, External Equity',
        columnName: 'MR-RDEE',
        propertyName: MetricPropertyNames.mozRankRootExternalEquity,
        description: `
        The fraction, derived solely from external links, of the composite MozRank of all pages on the URL's
        root domain, in the normalized 2-point score
    `,
        tooltipContent: (
            <span>
                The fraction, derived solely from external links, of the composite MozRank of all pages on the
                URL&apos;s root domain, in the normalized 2-point score
            </span>
        ),
    },
    {
        id: 9,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_TEN,
        name: 'MozRank: Subdomain',
        columnName: 'MR-SD',
        propertyName: MetricPropertyNames.mozRankSubdomain,
        description: "The MozRank of the URL's subdomain, in the normalized 2-point score",
        tooltipContent: <span>The MozRank of the URL&apos;s subdomain, in the normalized 2-point score</span>,
    },
    {
        id: 10,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_TEN,
        name: 'MozRank: Subdomain Combined',
        columnName: 'MR-SDC',
        propertyName: MetricPropertyNames.mozRankSubdomainCombined,
        description: 'The combined MozRank of all pages on the root domain, in the normalized 2-point score',
        tooltipContent: (
            <span>The combined MozRank of all pages on the root domain, in the normalized 2-point score</span>
        ),
    },
    {
        id: 11,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_TEN,
        name: 'MozRank: Subdomain, External Equity',
        columnName: 'MR-SDEE',
        propertyName: MetricPropertyNames.mozRankSubdomainExternalEquity,
        description: `
        The fraction, derived solely from external links, of the composite MozRank of all pages on the URL's
        subdomain, in the normalized 2-digit score
    `,
        tooltipContent: (
            <span>
                The fraction, derived solely from external links, of the composite MozRank of all pages on the URL&apos;
                subdomain, in the normalized 2-digit score
            </span>
        ),
    },
    {
        id: 12,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_TEN,
        name: 'MozTrust',
        columnName: 'MT',
        propertyName: MetricPropertyNames.mozTrust,
        description: `
        Similar to MozRank, but rather than measuring raw link popularity (link equity) from all pages and
        links equally, it measures a form of link equity that is tied to the trustworthiness of the linking website.
        MozTrust is based on calculating link “distance” between a given page and a “seed” site — a specific,
        known trust source (website) on the Internet.
    `,
        tooltipContent: (
            <span>
                <strong>MozTrust (MT)</strong> - developed by <strong>Moz</strong> is similar to MozRank, but rather
                than measuring <strong>raw link popularity (link equity)</strong>
                <strong>equally</strong>, it measures a form of link equity that is tied to the
                <strong>trustworthiness of the linking website</strong>.
            </span>
        ),
    },
    {
        id: 13,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_TEN,
        name: 'MozTrust: Subdomain',
        columnName: 'MT-SD',
        propertyName: MetricPropertyNames.mozTrustSubdomain,
        description: 'The MozTrust of the subdomain of the URL, in the normalized 2-point score',
        tooltipContent: <span>The MozTrust of the subdomain of the URL, in the normalized 2-point score</span>,
    },
    {
        id: 14,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_TEN,
        name: 'MozTrust: Root Domain',
        columnName: 'MT-RD',
        propertyName: MetricPropertyNames.mozTrustRoot,
        description: 'The MozTrust of the root domain of the URL, in the normalized 2-point score',
        tooltipContent: <span>The MozTrust of the root domain of the URL, in the normalized 2-point score</span>,
    },
    {
        id: 15,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Authority Links',
        columnName: 'AL',
        propertyName: MetricPropertyNames.authorityLinks,
        description: 'The total number of external equity (authority) links to the URL',
        tooltipContent: (
            <span>
                The total number of <strong>external equity (authority)</strong>
                links to the URL.
            </span>
        ),
    },
    {
        id: 16,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'All Links',
        columnName: 'AL',
        propertyName: MetricPropertyNames.links,
        description: 'The number of links (equity or nonequity, internal or external) to the URL',
        tooltipContent: <span>The number of links (equity or nonequity, internal or external) to the URL</span>,
    },
    {
        id: 17,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'External Links',
        columnName: 'EL',
        propertyName: MetricPropertyNames.externalLinks,
        description: 'The number of external links to the URL, including nofollowed links',
        tooltipContent: <span>The number of external links to the URL, including nofollowed links</span>,
    },
    {
        id: 18,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'External Links to Root Domain',
        columnName: 'EL-RD',
        propertyName: MetricPropertyNames.externalLinksToRoot,
        description: 'The number of external links to the root domain, including nofollowed links',
        tooltipContent: <span>The number of external links to the root domain, including nofollowed links</span>,
    },
    {
        id: 19,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'External Links to Subdomain',
        columnName: 'EL-SD',
        propertyName: MetricPropertyNames.externalLinksToSubdomain,
        description: 'The number of external links to the subdomain, including nofollowed links',
        tooltipContent: <span>The number of external links to the subdomain, including nofollowed links</span>,
    },
    {
        id: 20,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Subdomain External Links',
        columnName: 'SD-EL',
        propertyName: MetricPropertyNames.subdomainExternalLinks,
        description: 'The number of external equity links to the subdomain of the URL',
        tooltipContent: <span>The number of external equity links to the subdomain of the URL</span>,
    },
    {
        id: 21,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Links to Subdomain',
        columnName: 'LTSD',
        propertyName: MetricPropertyNames.linksToSubdomain,
        description: 'The total number of links (including internal and nofollow links) to the subdomain of the URL',
        tooltipContent: (
            <span>The total number of links (including internal and nofollow links) to the subdomain of the URL</span>
        ),
    },
    {
        id: 22,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Links to Root Domain',
        columnName: 'LTRD',
        propertyName: MetricPropertyNames.linksToRoot,
        description: 'The total number of links, including internal and nofollow links, to the root domain of the URL',
        tooltipContent: (
            <span>The total number of links, including internal and nofollow links, to the root domain of the URL</span>
        ),
    },
    {
        id: 23,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Subdomains Linking',
        columnName: 'SDL',
        propertyName: MetricPropertyNames.subdomainLinking,
        description: 'The number of subdomains with any pages linking to the URL',
        tooltipContent: <span>The number of subdomains with any pages linking to the URL</span>,
    },
    {
        id: 24,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Subdomain, Subdomains Linking',
        columnName: 'SDSDL',
        propertyName: MetricPropertyNames.subdomainLinkingToSubdomain,
        description: 'The number of subdomains with any pages linking to the subdomain of the URL',
        tooltipContent: <span>The number of subdomains with any pages linking to the subdomain of the URL</span>,
    },
    {
        id: 25,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Root Domain, Root Domains Linking',
        columnName: 'RDRDL',
        propertyName: MetricPropertyNames.rootDomainRootDomainsLinking,
        description: 'The number of root domains with any pages linking to the root domain of the URL',
        tooltipContent: <span>The number of root domains with any pages linking to the root domain of the URL</span>,
    },
    {
        id: 26,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Root Domains Linking to Subdomain',
        columnName: 'RDLTSD',
        propertyName: MetricPropertyNames.rootDomainsLinkingToSubdomain,
        description: 'The number of root domains with at least one link to the subdomain of the URL',
        tooltipContent: <span>The number of root domains with at least one link to the subdomain of the URL</span>,
    },
    {
        id: 27,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Root Domains Linking',
        columnName: 'RDL',
        propertyName: MetricPropertyNames.rootDomainsLinking,
        description: 'The number of root domains with any pages linking to the URL',
        tooltipContent: <span>The number of root domains with any pages linking to the URL</span>,
    },
    {
        id: 28,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Linking C Blocks',
        columnName: 'LCB',
        propertyName: MetricPropertyNames.linkingCBlocks,
        description: 'The number of links from the same C class IP addresses',
        tooltipContent: <span>The number of links from the same C class IP addresses</span>,
    },
    {
        id: 29,
        type: MetricTypes.MOZ,
        valueType: MetricValueTypes.ZERO_TO_TEN,
        name: 'Subdomain Spam Score',
        columnName: 'SDSS',
        propertyName: MetricPropertyNames.spamScoreSubdomain,
        description: "Spam score for the page's subdomain",
        tooltipContent: <span>Spam score for the page&apos;s subdomain</span>,
    },
    {
        id: 30,
        type: MetricTypes.SOCIAL,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Facebook Shares',
        columnName: 'FB',
        propertyName: MetricPropertyNames.facebookShares,
        description: 'The number of Facebook shares for given URL',
        tooltipContent: (
            <span>
                The number of <strong>Facebook shares</strong> for given URL.
            </span>
        ),
    },
    {
        id: 31,
        type: MetricTypes.OTHER,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Website Popularity Rank',
        columnName: 'WPR',
        propertyName: MetricPropertyNames.topRank,
        description: `
        Website Popularity Rank (WPR) is a score developed by Tranco and is a rough measure of a website's popularity
        as compared with all other websites on the Internet. Considering the number of unique visitors
        and number of pages viewed on each visit.
    `,
        tooltipContent: (
            <span>
                <strong>Website Popularity Rank (WPR)</strong> - developed by <strong>Tranco</strong>, is a rough
                measure of a website&apos;s popularity as compared with all other websites on the Internet. Considering
                <strong>the number of unique visitors</strong> &nbsp;and number of pages viewed on each visit.
            </span>
        ),
    },
    {
        id: 32,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_TEN,
        name: 'AC Rank',
        columnName: 'ACR',
        propertyName: MetricPropertyNames.acRank,
        description: 'The older AC Rank metric. Deprecated in favor of Citation Flow.',
        tooltipContent: <span>The older AC Rank metric. Deprecated in favor of Citation Flow.</span>,
    },
    {
        id: 33,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'External BackLinks',
        columnName: 'Links',
        propertyName: MetricPropertyNames.externalBackLinks,
        description: `
        Number of external backlinks for this item. This includes all type of links excluding mentions. Including links
        deleted within the last 90 days when querying fresh or links marked as deleted when querying historic indexes.
    `,
        tooltipContent: (
            <span>
                Number of <strong>external backlinks</strong> for this item, provided by <strong>Majestic</strong>. This
                includes all type of links excluding mentions.
            </span>
        ),
    },
    {
        id: 34,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'External Back Links EDU',
        columnName: 'EBE',
        propertyName: MetricPropertyNames.externalBackLinksEdu,
        description: `
        Number of external backlinks from EDUcational (includes .AC. versions and regional .EDU.xx TLDs) sites.
    `,
        tooltipContent: (
            <span>
                Number of external backlinks from EDUcational (includes .AC. versions and regional .EDU.xx TLDs) sites.
            </span>
        ),
    },
    {
        id: 35,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'External Back Links EDU (Exact)',
        columnName: 'EBEE',
        propertyName: MetricPropertyNames.externalBackLinksEduExact,
        description: `
        Same as the non-exact metric, but applies only to domains with exact TLDs such as .EDU thus
        excluding any regional versions.
    `,
        tooltipContent: (
            <span>
                Same as the non-exact metric, but applies only to domains with exact TLDs such as .EDU thus excluding
                any regional versions.
            </span>
        ),
    },
    {
        id: 36,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'External Back Links GOV',
        columnName: 'EBG',
        propertyName: MetricPropertyNames.externalBackLinksGov,
        description: `
        Number of external backlinks from GOVernment sites (includes .MIL. and regional versions such .MIL.*).
    `,
        tooltipContent: (
            <span>
                Number of external backlinks from GOVernment sites (includes .MIL. and regional versions such .MIL.*).
            </span>
        ),
    },
    {
        id: 37,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'External Back Links GOV (Exact)',
        columnName: 'EBGE',
        propertyName: MetricPropertyNames.externalBackLinksGovExact,
        description: `
        Same as the non-exact metric, but applies only to domains with exact TLDs such as .GOV/.MIL thus
        excluding any regional versions.
    `,
        tooltipContent: (
            <span>
                Same as the non-exact metric, but applies only to domains with exact TLDs such as .GOV/.MIL thus
                excluding any regional versions.
            </span>
        ),
    },
    {
        id: 38,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Indexed URLs',
        columnName: 'IURL',
        propertyName: MetricPropertyNames.indexedURLs,
        description: `
        Number of URLs for given item type present in index - these are NOT backlinks, but rather URLs belonging to
        given domain or subdomain: this metric is useful as an indication of domain content size.
    `,
        tooltipContent: (
            <span>
                Number of URLs for given item type present in index - these are NOT backlinks, but rather URLs belonging
                to given domain or subdomain: this metric is useful as an indication of domain content size.
            </span>
        ),
    },
    {
        id: 39,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Out Domains External',
        columnName: 'ODE',
        propertyName: MetricPropertyNames.outDomainsExternal,
        description: `
        Number of external domains crawled URL links out to. Experimental - we do not recommend to rely on this value.
    `,
        tooltipContent: (
            <span>
                Number of external domains crawled URL links out to. Experimental - we do not recommend to rely on this
                value.
            </span>
        ),
    },
    {
        id: 40,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Out Links External',
        columnName: 'OLE',
        propertyName: MetricPropertyNames.outLinksExternal,
        description: 'Same as "Out Domains External" but only counts external out links',
        tooltipContent: (
            <span>
                Same as <strong>Out Domains External</strong> but only counts external out links
            </span>
        ),
    },
    {
        id: 41,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Out Links Internal',
        columnName: 'OLI',
        propertyName: MetricPropertyNames.outLinksInternal,
        description: 'Same as "Out Links External" but only counts internal links',
        tooltipContent: (
            <span>
                Same as <strong>Out Links External</strong> but only counts internal links
            </span>
        ),
    },
    {
        id: 42,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Referring Domains',
        columnName: 'RD',
        propertyName: MetricPropertyNames.refDomains,
        description: `
        Number of referring domains known for this URL. Referring domain, also known as "ref domain",
        is a website which has a backlink pointing to a page, or link, to the site you are analysing.
    `,
        tooltipContent: (
            <span>
                Number of <strong>referring domains</strong> for this URL. Referring domain, is a{' '}
                <strong>website which has a backlink</strong> pointing to a page, or link, to the site you are
                analysing.
            </span>
        ),
    },
    {
        id: 43,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Referring Domains EDU',
        columnName: 'RDE',
        propertyName: MetricPropertyNames.refDomainsEdu,
        description: `
        Number of referring domains from EDUcational (includes .AC. versions and regional .EDU.xx TLDs) sites.
    `,
        tooltipContent: (
            <span>
                Number of referring domains from EDUcational (includes .AC. versions and regional .EDU.xx TLDs) sites.
            </span>
        ),
    },
    {
        id: 44,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Referring Domains EDU (Exact)',
        columnName: 'RDEE',
        propertyName: MetricPropertyNames.refDomainsEduExact,
        description: `
        Same as non-exact metric, but applies only to domains with exact TLDs such as .EDU
        thus excluding any regional versions.
    `,
        tooltipContent: (
            <span>
                Same as non-exact metric, but applies only to domains with exact TLDs such as .EDU thus excluding any
                regional versions.
            </span>
        ),
    },
    {
        id: 45,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Referring Domains GOV',
        columnName: 'RDG',
        propertyName: MetricPropertyNames.refDomainsGov,
        description: `
        Number of referring domains from GOVernment sites (includes .MIL. and regional versions such .MIL.*).
    `,
        tooltipContent: (
            <span>
                Number of referring domains from GOVernment sites (includes .MIL. and regional versions such .MIL.*).
            </span>
        ),
    },
    {
        id: 46,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Referring Domains GOV (Exact)',
        columnName: 'RDGE',
        propertyName: MetricPropertyNames.refDomainsGovExact,
        description: `
        Same as non-exact metric, but applies only to domains with exact TLDs such as .GOV/.MIL
        thus excluding any regional versions.
    `,
        tooltipContent: (
            <span>
                Same as non-exact metric, but applies only to domains with exact TLDs such as .GOV/.MIL thus excluding
                any regional versions.
            </span>
        ),
    },
    {
        id: 47,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Referring IPs',
        columnName: 'RI',
        propertyName: MetricPropertyNames.refIPs,
        description: `
        Number of referring IP addresses provided by Majestic.
        A Referring IP refers to an IP address which hosts at least one website that contains a link
        to the given target URL or Domain. Many domains, (websites), can be hosted on one IP address.
    `,
        tooltipContent: (
            <span>
                Number of <strong>referring IP addresses</strong> provided by <strong>Majestic</strong>. Many domains,
                (websites), can be hosted on one IP address.
            </span>
        ),
    },
    {
        id: 48,
        type: MetricTypes.MAJESTIC,
        valueType: MetricValueTypes.ZERO_TO_INFINITY,
        name: 'Referring SubNets',
        columnName: 'RSN',
        propertyName: MetricPropertyNames.refSubNets,
        description: 'Number of referring C-class subnets (SEO wise here, ie: 212.100.100.*)',
        tooltipContent: <span>Number of referring C-class subnets (SEO wise here, ie: 212.100.100.*)</span>,
    },
];
/* eslint-enable max-len */

export default Metrics;
