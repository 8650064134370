import React from 'react';
import { bool } from 'prop-types';
import Urls from 'mangools-commons/lib/constants/Urls';

function HelpDropdown(props) {
    if (props.visible) {
        return (
            <aside
                className="uk-dropdown uk-dropdown-align-right uk-dropdown-right uk-display-block"
                style={{ width: '210px' }}
            >
                <ul className="mg-listnav is-small font-14" style={{ maxHeight: '300px' }}>
                    <li>
                        <a
                            className="uk-display-block"
                            href={`${Urls.SERPCHECKER_GUIDE_URL}?ref=header-app-sch`}
                            rel="noopener"
                            target="_blank"
                        >
                            SERPChecker guide
                        </a>
                    </li>
                    <li>
                        <a
                            className="uk-display-block"
                            href={`${Urls.MANGOOLS_KEYWORD_RESEARCH_GUIDE_URL}?ref=header-app-sch`}
                            rel="noopener"
                            target="_blank"
                        >
                            Keyword research guide
                        </a>
                    </li>
                    <li>
                        <a
                            className="uk-display-block"
                            href={`${Urls.MANGOOLS_SEO_ACADEMY_URL}?ref=header-app-sch`}
                            rel="noopener"
                            target="_blank"
                        >
                            SEO Guide
                        </a>
                    </li>
                    <li>
                        <a
                            className="uk-display-block"
                            href={`${Urls.MANGOOLS_BLOG_URL}?ref=header-app-sch`}
                            rel="noopener"
                            target="_blank"
                        >
                            Mangools Blog
                        </a>
                    </li>
                    <li>
                        <a
                            className="uk-display-block"
                            href={`${Urls.MANGOOLS_AFFILIATE_URL}?ref=header-app-sch`}
                            rel="noopener"
                            target="_blank"
                        >
                            Affiliate Program
                        </a>
                    </li>
                    <li>
                        <a
                            className="uk-display-block"
                            href={`${Urls.MANGOOLS_FAQ_URL}?ref=header-app-sch`}
                            rel="noopener"
                            target="_blank"
                        >
                            FAQ
                        </a>
                    </li>
                </ul>
            </aside>
        );
    } else {
        return null;
    }
}

HelpDropdown.propTypes = {
    visible: bool.isRequired,
};

export default HelpDropdown;
