import React from 'react';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';

import AccessDeniedMessage from 'components/messages/AccessDeniedMessage';
import ExportMessage from 'components/messages/ExportMessage';
import FailureMessage from 'components/messages/FailureMessage';
import KeywordDifficultyInfoMessage from 'components/messages/KeywordDifficultyInfoMessage';
import LoggedOutMessage from 'components/messages/LoggedOutMessage';
import NeedToSignInMessage from 'components/messages/NeedToSignInMessage';
import NoConnectionMessage from 'components/messages/NoConnectionMessage';
import PricingMessage from 'components/messages/PricingMessage';
import SerpFeatureInfoMessage from 'components/messages/SerpFeatureInfoMessage';
import SerpFeaturesImpactInfoMessage from 'components/messages/SerpFeaturesImpactInfoMessage';
import ShortcutsMessage from 'components/messages/ShortcutsMessage';

import { requestedResultsExportAction } from 'actions/dataActions';

import {
    closeAccessDeniedMessage,
    closeExportMessage,
    closeFailureMessage,
    closeKeywordDifficultyInfoMessage,
    closeLoggedOutMessage,
    closeNoConnectionMessage,
    closePricingMessage,
    closeSerpFeatureInfoMessage,
    closeSerpFeaturesImpactInfoMessage,
    setDashboardTab,
    closeShortcutsMessage,
} from 'actions/uiActions';

import { userPlanTypeSelector } from 'selectors/userSelectors';

import {
    pricingMessageVisibilitySelector,
    exportMessageVisiblitySelector,
    shortcutsMessageVisibilitySelector,
} from 'selectors/uiSelectors';

import { DashboardTabs } from 'constants/Other';

function MessageContainer(props) {
    return (
        <div>
            <AccessDeniedMessage visible={props.accessDeniedVisible} onClose={props.onCloseAccessDeniedMessage} />
            <NeedToSignInMessage visible={props.needToSignInVisible} />
            <FailureMessage
                details={props.failureDetails}
                header={props.failureHeader}
                onClose={props.onCloseFailureMessage}
                visible={props.failureVisible}
            />
            <ExportMessage
                exporting={props.exporting}
                onClose={props.onCloseExportMessage}
                onExportResults={props.onExportResults}
                visible={props.exportVisible}
            />
            <NoConnectionMessage onClose={props.onCloseNoConnectionMessage} visible={props.noConnectionVisible} />
            <PricingMessage
                onClose={props.onClosePricingMessage}
                userPlanType={props.userPlanType}
                visible={props.pricingVisible}
            />
            <LoggedOutMessage onClose={props.onCloseLoggedOutMessage} visible={props.loggedOutVisible} />
            <KeywordDifficultyInfoMessage
                onClose={props.onCloseKeywordDifficultyInfoMessage}
                visible={props.keywordDifficultyInfoVisible}
            />
            <SerpFeatureInfoMessage
                featureType={props.serpFeatureInfoFeatureType}
                onClose={props.onCloseSerpFeatureInfoMessage}
                onShowSnapshot={props.onShowSnapshot}
                subFeature={props.serpFeatureInfoSubFeature}
                visible={props.serpFeatureInfoVisible}
            />
            <SerpFeaturesImpactInfoMessage
                onClose={props.onCloseSerpFeaturesImpactInfoMessage}
                visible={props.serpFeaturesImpactInfoVisible}
            />
            <ShortcutsMessage visible={props.shortcutsMessageVisible} onClose={props.onCloseShortcutsMessage} />
        </div>
    );
}

MessageContainer.propTypes = {
    accessDeniedVisible: bool.isRequired,
    exportVisible: bool.isRequired,
    exporting: bool.isRequired,
    failureDetails: string,
    failureHeader: string,
    failureVisible: bool.isRequired,
    keywordDifficultyInfoVisible: bool.isRequired,
    loggedOutVisible: bool.isRequired,
    needToSignInVisible: bool.isRequired,
    noConnectionVisible: bool.isRequired,
    onCloseAccessDeniedMessage: func.isRequired,
    onCloseExportMessage: func.isRequired,
    onCloseFailureMessage: func.isRequired,
    onCloseKeywordDifficultyInfoMessage: func.isRequired,
    onCloseLoggedOutMessage: func.isRequired,
    onCloseNoConnectionMessage: func.isRequired,
    onClosePricingMessage: func.isRequired,
    onCloseSerpFeatureInfoMessage: func.isRequired,
    onCloseSerpFeaturesImpactInfoMessage: func.isRequired,
    onExportResults: func.isRequired,
    onShowSnapshot: func.isRequired,
    pricingVisible: bool.isRequired,
    serpFeatureInfoFeatureType: string.isRequired,
    serpFeatureInfoSubFeature: bool.isRequired,
    serpFeatureInfoVisible: bool.isRequired,
    serpFeaturesImpactInfoVisible: bool.isRequired,
    userPlanType: string.isRequired,
    shortcutsMessageVisible: bool.isRequired,
    onCloseShortcutsMessage: func.isRequired,
};

MessageContainer.defaultProps = {
    failureDetails: null,
};

const mapStateToProps = (state, _ownProps) => ({
    accessDeniedVisible: state.ui.messages.accessDenied.visibility,
    exportVisible: exportMessageVisiblitySelector(state),
    exporting: state.export.exporting,
    failureDetails: state.ui.messages.failure.details,
    failureHeader: state.ui.messages.failure.header,
    failureVisible: state.ui.messages.failure.visibility,
    keywordDifficultyInfoVisible: state.ui.messages.keywordDifficultyInfo.visibility,
    loggedOutVisible: state.ui.messages.loggedOut.visibility,
    needToSignInVisible: state.ui.messages.needToSignIn.visibility,
    noConnectionVisible: state.ui.messages.noConnection.visibility,
    pricingVisible: pricingMessageVisibilitySelector(state),
    serpFeatureInfoFeatureType: state.ui.messages.serpFeatureInfo.featureType,
    serpFeatureInfoSubFeature: state.ui.messages.serpFeatureInfo.subFeature,
    serpFeatureInfoVisible: state.ui.messages.serpFeatureInfo.visibility,
    serpFeaturesImpactInfoVisible: state.ui.messages.serpFeaturesImpactInfo.visibility,
    userPlanType: userPlanTypeSelector(state),
    shortcutsMessageVisible: shortcutsMessageVisibilitySelector(state),
});

const mapDispatchToProps = (dispatch, _ownProps) => ({
    onCloseAccessDeniedMessage() {
        dispatch(closeAccessDeniedMessage());
    },
    onCloseExportMessage() {
        dispatch(closeExportMessage());
    },
    onCloseFailureMessage() {
        dispatch(closeFailureMessage());
    },
    onCloseKeywordDifficultyInfoMessage() {
        dispatch(closeKeywordDifficultyInfoMessage());
    },
    onCloseLoggedOutMessage() {
        dispatch(closeLoggedOutMessage());
    },
    onCloseNoConnectionMessage() {
        dispatch(closeNoConnectionMessage());
    },
    onClosePricingMessage() {
        dispatch(closePricingMessage());
    },
    onCloseSerpFeatureInfoMessage() {
        dispatch(closeSerpFeatureInfoMessage());
    },
    onCloseSerpFeaturesImpactInfoMessage() {
        dispatch(closeSerpFeaturesImpactInfoMessage());
    },
    onExportResults({ exportType, includeSerpFeatures, includeGlobalMetrics }) {
        dispatch(requestedResultsExportAction({ exportType, includeSerpFeatures, includeGlobalMetrics }));
    },
    onShowSnapshot() {
        dispatch(setDashboardTab(DashboardTabs.SNAPSHOT));
    },
    onCloseShortcutsMessage() {
        dispatch(closeShortcutsMessage());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageContainer);
