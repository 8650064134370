export const FEATURE_TYPES = {
    ADV_BOTTOM: 'ADV_BOTTOM',
    ADV_TOP: 'ADV_TOP',
    ADS: 'ADS',
    ANSWER_BOX: 'ANSWER_BOX',
    FEATURED_SNIPPET: 'FEATURED_SNIPPET',
    FLIGHTS: 'FLIGHTS',
    IMG_PACK: 'IMG_PACK',
    INDEPTH_ARTICLES: 'INDEPTH_ARTICLES',
    INSTALL_APP: 'INSTALL_APP',
    INSTALL_APP_CAROUSEL: 'INSTALL_APP_CAROUSEL',
    JOB_POSTING: 'JOB_POSTING',
    KNO_GRAPH: 'KNO_GRAPH',
    MAP_PACK: 'MAP_PACK',
    ORGANIC: 'ORGANIC',
    SHOP_RESULTS: 'SHOP_RESULTS',
    SIMILAR_ENTITIES: 'SIMILAR_ENTITIES',
    SIMILAR_PLACES: 'SIMILAR_PLACES',
    SIMILAR_QUERIES: 'SIMILAR_QUERIES',
    SIMILAR_QUESTIONS: 'SIMILAR_QUESTIONS',
    SPECIFY_QUERY: 'SPECIFY_QUERY',
    SPELL_ERROR: 'SPELL_ERROR',
    TOP_CAROUSEL: 'TOP_CAROUSEL',
    TOP_STORIES: 'TOP_STORIES',
    TOP_STORIES_ROW: 'TOP_STORIES_ROW',
    VIDEO_BIG: 'VIDEO_BIG',
    VIDEO_PACK: 'VIDEO_PACK',
    SUGGESTION: 'SUGGESTION',
    SIMILAR_ENTITIES2: 'SIMILAR_ENTITIES2',
};

export const SUB_FEATURE_TYPES = {
    AD_FEATURE: 'AD_FEATURE',
    BREADCRUMBS: 'BREADCRUMBS',
    EVENT_LIST: 'EVENT_LIST',
    IMAGE_GALLERY: 'IMAGE_GALLERY',
    IMAGE_THUMBNAIL: 'IMAGE_THUMBNAIL',
    ITEM: 'ITEM',
    PRERENDER: 'PRERENDER',
    RATE: 'RATE',
    SEARCH_BOX: 'SEARCH_BOX',
    SIMILAR_RESULTS: 'SIMILAR_RESULTS',
    SITELINKS: 'SITELINKS',
    TOP_STORIES_FROM_WEBSITE: 'TOP_STORIES_FROM_WEBSITE',
    TWEET: 'TWEET',
    VIDEO_THUMBNAIL: 'VIDEO_THUMBNAIL',
};
