import * as React from 'react';
import serpIcons from 'images/symbol-defs.svg';

const SerpIcon = ({ icon }: SvgIconProps) => {
    return (
        <svg>
            <use xlinkHref={`${serpIcons}#${icon}`} />
        </svg>
    );
};

interface SvgIconProps {
    icon: string
}

export default SerpIcon;