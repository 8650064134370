const MetricPropertyNames = {
    acRank: 'acRank',
    topRank: 'topRank',
    authorityLinks: 'authorityLinks',
    citationFlow: 'citationFlow',
    domainAuthority: 'domainAuthority',
    externalBackLinks: 'externalBackLinks',
    externalBackLinksEdu: 'externalBackLinksEdu',
    externalBackLinksEduExact: 'externalBackLinksEduExact',
    externalBackLinksGov: 'externalBackLinksGov',
    externalBackLinksGovExact: 'externalBackLinksGovExact',
    externalLinks: 'externalLinks',
    externalLinksToRoot: 'externalLinksToRoot',
    externalLinksToSubdomain: 'externalLinksToSubdomain',
    facebookShares: 'facebookShares',
    indexedURLs: 'indexedURLs',
    linkingCBlocks: 'linkingCBlocks',
    links: 'links',
    linksToRoot: 'linksToRoot',
    linksToSubdomain: 'linksToSubdomain',
    mozRank: 'mozRank',
    mozRankExternalEquity: 'mozRankExternalEquity',
    mozRankRoot: 'mozRankRoot',
    mozRankRootCombined: 'mozRankRootCombined',
    mozRankRootExternalEquity: 'mozRankRootExternalEquity',
    mozRankSubdomain: 'mozRankSubdomain',
    mozRankSubdomainCombined: 'mozRankSubdomainCombined',
    mozRankSubdomainExternalEquity: 'mozRankSubdomainExternalEquity',
    mozTrust: 'mozTrust',
    mozTrustRoot: 'mozTrustRoot',
    mozTrustSubdomain: 'mozTrustSubdomain',
    outDomainsExternal: 'outDomainsExternal',
    outLinksExternal: 'outLinksExternal',
    outLinksInternal: 'outLinksInternal',
    pageAuthority: 'pageAuthority',
    refDomains: 'refDomains',
    refDomainsEdu: 'refDomainsEdu',
    refDomainsEduExact: 'refDomainsEduExact',
    refDomainsGov: 'refDomainsGov',
    refDomainsGovExact: 'refDomainsGovExact',
    refIPs: 'refIPs',
    refSubNets: 'refSubNets',
    rootDomainRootDomainsLinking: 'rootDomainRootDomainsLinking',
    rootDomainsLinking: 'rootDomainsLinking',
    rootDomainsLinkingToSubdomain: 'rootDomainsLinkingToSubdomain',
    spamScoreSubdomain: 'spamScoreSubdomain',
    subdomainExternalLinks: 'subdomainExternalLinks',
    subdomainLinking: 'subdomainLinking',
    subdomainLinkingToSubdomain: 'subdomainLinkingToSubdomain',
    trustFlow: 'trustFlow',
};

export default MetricPropertyNames;
