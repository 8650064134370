import ActionTypes from 'constants/ActionTypes';

export const fetchingAction = () => ({
    type: ActionTypes.USER_DATA_FETCHING,
});

export const receivedAction = payload => ({
    type: ActionTypes.USER_DATA_RECEIVED,
    error: false,
    payload,
});

export const errorAction = payload => ({
    type: ActionTypes.USER_DATA_ERROR,
    error: true,
    payload,
});

export const requestedLimitsAction = () => ({
    type: ActionTypes.USER_LIMIT_DATA_REQUESTED,
});

export const fetchingLimitsAction = () => ({
    type: ActionTypes.USER_LIMIT_DATA_FETCHING,
});

export const receivedLimitsAction = payload => ({
    type: ActionTypes.USER_LIMIT_DATA_RECEIVED,
    error: false,
    payload,
});

export const errorLimitsAction = payload => ({
    type: ActionTypes.USER_LIMIT_DATA_ERROR,
    error: true,
    payload,
});

export const skippedLimitsAction = () => ({
    type: ActionTypes.USER_LIMIT_DATA_SKIPPED,
});

export const receivedLogoutAction = () => ({
    type: ActionTypes.USER_LOGOUT_RECEIVED,
});

export const requestedLogoutAction = () => ({
    type: ActionTypes.USER_LOGOUT_REQUESTED,
});

export const errorLogoutAction = payload => ({
    type: ActionTypes.USER_LOGOUT_ERROR,
    error: true,
    payload,
});

export const errorCheckStatusAction = payload => ({
    type: ActionTypes.USER_CHECK_STATUS_ERROR,
    error: true,
    payload,
});

export const skippedCheckStatusAction = () => ({
    type: ActionTypes.USER_CHECK_STATUS_SKIPPED,
});

export const skippedUserAction = () => ({
    type: ActionTypes.DATA_USER_DATA_SKIPPED,
});

export const setAuthTokens = ({ ssoTicket, loginToken }) => ({
    type: ActionTypes.DATA_USER_AUTH_TOKENS_RECEIVED,
    error: false,
    payload: {
        ssoTicket,
        loginToken,
    },
});

export const setUnleashSessionAction = (payload) => ({
    type: ActionTypes.DATA_USER_SET_UNLEASH_SESSION,
    error: true,
    payload,
});
