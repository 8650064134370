import { browserHistory } from 'react-router';
import queryString from 'query-string';
import Paths from 'mangools-commons/lib/constants/Paths';

import config from 'appConfig';

import RoutePaths from 'constants/RoutePaths';

/**
 * Browser history wrapper service.
 */
class RouterService {
    /**
     * Navigate to next page based on provided route and params.
     *
     * @param {route: String} RoutePaths value
     * @param {params: Object} Query params (keyword, platformId, locationId)
     * @return {void}
     */
    static navigate(route, params) {
        const traslatedParams = {};

        if (route === RoutePaths.DASHBOARD) {
            traslatedParams.keyword = params.keyword;
            traslatedParams.location = params.locationId;
            traslatedParams.platform = params.platformId;
        }

        browserHistory.push({
            pathname: route,
            query: traslatedParams,
            state: null,
        });
    }

    static replaceWithParams(params) {
        browserHistory.replace(params);
    }

    /**
     * Navigate to root page.
     *
     * @return {void}
     */
    static navigateToRoot() {
        browserHistory.push({
            pathname: RoutePaths.ROOT,
            query: {},
            state: null,
        });
    }

    static navigateBack() {
        browserHistory.goBack();
    }

    static isSame(pathname, routePath) {
        return pathname === routePath || pathname === `/${routePath}`;
    }

    static redirect(url, query = {}) {
        const stringifiedQuery = queryString.stringify(query);
        window.location.replace(`${url}${stringifiedQuery.length > 0 ? `?${stringifiedQuery}` : ''}`);
    }

    static redirectToAuth(redirectUrl) {
        RouterService.redirect(`${config.MANGOOLS_API_HOST}${Paths.MANGOOLS_AUTH_PATH}`, { redirect: redirectUrl });
    }
}

export default RouterService;
