import React, { Component } from 'react';
import { bool, func, number, string } from 'prop-types';
import classnames from 'classnames';
import shallowCompare from 'react-addons-shallow-compare';

const CHECKBOX_TYPE = 'checkbox';

class MetricsItem extends Component {
    constructor() {
        super();
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleIndirectClick = this.handleIndirectClick.bind(this);
        this.checkbox = null;
    }

    shouldComponentUpdate(newProps, newState) {
        return shallowCompare(this, newProps, newState);
    }

    handleCheckboxChange(e) {
        if (e.target.type === CHECKBOX_TYPE) {
            e.stopPropagation();
            this.props.onSelectChange({ id: this.props.id, selected: e.target.checked });
        }
    }

    handleIndirectClick(e) {
        if (e.target.type !== CHECKBOX_TYPE) {
            e.stopPropagation();
            this.props.onSelectChange({ id: this.props.id, selected: !this.checkbox.checked });
        }
    }

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    render() {
        const classes = classnames({
            'mg-card is-hover uk-flex mg-padding-15 mg-margin-b-15 cursor-pointer': true,
            'is-active': this.props.selected,
        });

        return (
            <div className="uk-width-medium-1-3 uk-width-small-1-1">
                <div className={classes} onClick={this.handleIndirectClick}>
                    <div className="mg-margin-r-10" onClick={this.handleIndirectClick}>
                        <input
                            type="checkbox"
                            checked={this.props.selected}
                            onChange={this.handleCheckboxChange}
                            ref={c => (this.checkbox = c)}
                        />
                    </div>
                    <div className="uk-width-8-10" onClick={this.handleIndirectClick}>
                        <strong className="font-14" onClick={this.handleIndirectClick}>
                            {this.props.name}
                        </strong>
                        <p className="font-12 color-grey" onClick={this.handleIndirectClick}>
                            {this.props.description}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
}

MetricsItem.propTypes = {
    id: number.isRequired,
    name: string.isRequired,
    description: string.isRequired,
    selected: bool.isRequired,
    onSelectChange: func.isRequired,
};

export default MetricsItem;
