import React from 'react';
import { bool, func, number } from 'prop-types';
import { connect } from 'react-redux';

import AnnouncementMessage from 'components/messages/topLevel/AnnouncementMessage';
import NewUserMessage from 'components/messages/topLevel/NewUserMessage';

import { closeTopLevelAnnouncementMessage } from 'actions/uiActions';

import { newUserMessageVisibilitySelector, timerLimitSecondsSelector } from 'selectors/userSelectors';

import {
    announcementMesageVisibilitySelector,
    announcementsMessageDataSelector,
} from 'selectors/announcementsSelectors';

import AnnouncementType from 'types/AnnouncementType';

/**
 * Top Level messages and notifications wrapper.
 * Can be used for cookie info, notifications, etc.
 */
function TopLevelMessageContainer(props) {
    return (
        <>
            <AnnouncementMessage
                btnText={props.announcementMesageData.btnText}
                icon={props.announcementMesageData.icon}
                messageText={props.announcementMesageData.messageText}
                newTab={props.announcementMesageData.newTab}
                onClose={props.onCloseAnnouncementMessage}
                url={props.announcementMesageData.url}
                visible={props.announcementMesageVisible}
            />
            <NewUserMessage visible={props.newUserVisible} timerLimitSeconds={props.timerLimitSeconds} />
        </>
    );
}

const mapDispatchToProps = (dispatch, _ownProps) => ({
    onCloseAnnouncementMessage() {
        dispatch(closeTopLevelAnnouncementMessage());
    },
});

const mapStateToProps = (state, _ownProps) => ({
    announcementMesageData: announcementsMessageDataSelector(state),
    announcementMesageVisible: announcementMesageVisibilitySelector(state),
    newUserVisible: newUserMessageVisibilitySelector(state),
    timerLimitSeconds: timerLimitSecondsSelector(state),
});

TopLevelMessageContainer.propTypes = {
    announcementMesageData: AnnouncementType.isRequired,
    announcementMesageVisible: bool.isRequired,
    newUserVisible: bool.isRequired,
    onCloseAnnouncementMessage: func.isRequired,
    timerLimitSeconds: number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopLevelMessageContainer);
