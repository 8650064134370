export default {
    COMPARING_RESULT_DROPDOWN_ID: 'COMPARING_RESULT_DROPDOWN_ID',
    JUST_NOW: 'just now',
    KD_CACHE_TIME_DAYS: 30,
    MAX_TOP_RANK: Number.MAX_VALUE,
    MAX_KEYWORD_LENGTH: 100,
    NEW_KD_ALGO_DEPLOY_TS: 1537866000000,
};

export const DashboardTabs = {
    RESULTS: '1',
    SNAPSHOT: '2',
};
