import { call, spawn, select, takeEvery } from 'redux-saga/effects';
import omit from 'ramda/src/omit';
import queryString from 'query-string';

import RouterService from 'services/RouterService';
import config from 'appConfig';

import { currentRouteSelector, currentQuerySelector } from 'selectors/routingSelectors';

import ActionTypes from 'constants/ActionTypes';

function* navigate(action) {
    const { route, params } = action.payload;
    yield call(RouterService.navigate, route, params);
}

function* hideAuthTokens() {
    const pathname = yield select(currentRouteSelector);
    const query = yield select(currentQuerySelector);
    const queryWithtoutAuthTokens = omit(['sso_ticket', 'login_token'], query);
    const params = {
        pathname,
        query: queryWithtoutAuthTokens,
        state: null,
    };

    yield call(RouterService.replaceWithParams, params);
}

function* updateViewParamUrl(action) {
    const { payload: newTab } = action;
    const pathname = yield select(currentRouteSelector);
    const query = yield select(currentQuerySelector);

    yield call(RouterService.replaceWithParams, { pathname, query: { ...query, view: newTab } });
}

function* watchDashboardTabChange() {
    yield takeEvery(ActionTypes.UI_MISC_DASHBOARD_TAB_SET, updateViewParamUrl);
}

function* watchHideAuthTokensRequests() {
    yield takeEvery(ActionTypes.ROUTER_AUTH_TOKENS_HIDE_REQUESTED, hideAuthTokens);
}

export function* watchNavigationRequests() {
    yield takeEvery(ActionTypes.ROUTER_NAVIGATION_REQUESTED, navigate);
}

export function* redirectToAuth() {
    const pathname = yield select(currentRouteSelector);
    const query = yield select(currentQuerySelector);

    const queryWithtoutAuthTokens = omit(['sso_ticket', 'login_token'], query);
    const url = `${config.APPS_ROOT_HOST}${pathname}?${queryString.stringify(queryWithtoutAuthTokens)}`;

    yield call(RouterService.redirectToAuth, url);
}

export function* watchRouterRequests() {
    yield spawn(watchHideAuthTokensRequests);
    yield spawn(watchNavigationRequests);
    yield spawn(watchDashboardTabChange);
}
