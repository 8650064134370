import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AnnouncementMessage extends Component {
    constructor() {
        super();
        this.renderBody = this.renderBody.bind(this);
    }

    /* eslint-disable react/no-danger */
    renderBody() {
        return (
            <div className="uk-margin-remove uk-width-1-1 font-14">
                <FontAwesomeIcon
                    icon={this.props.icon}
                    className="mg-margin-r-10 uk-position-relative font-18"
                    style={{ top: '2px' }}
                />
                <span dangerouslySetInnerHTML={{ __html: this.props.messageText }} />
                <a
                    className="mg-btn is-orange is-gradient is-pill is-xsmall mg-margin-l-15 uk-position-relative"
                    href={`${this.props.url}?ref=annc-app-sch`}
                    rel="noopener"
                    style={{ top: '-2px' }}
                    target={this.props.newTab === true ? '_blank' : '_self'}
                >
                    {this.props.btnText}
                </a>
            </div>
        );
    }
    /* eslint-enable react/no-danger */

    render() {
        if (this.props.visible) {
            return (
                <div className="mg-page-message animated-once fade-in-down">
                    <div className="uk-container uk-flex uk-flex-middle uk-container-center uk-height-1-1">
                        {this.renderBody()}
                    </div>
                    <button
                        aria-label="close message"
                        className="mg-icon-btn uk-position-top-right mg-margin-t-15 mg-margin-r-15"
                        onClick={this.props.onClose}
                        title="close message"
                        type="button"
                    >
                        <FontAwesomeIcon icon="times" />
                    </button>
                </div>
            );
        } else {
            return null;
        }
    }
}

AnnouncementMessage.propTypes = {
    btnText: string.isRequired,
    icon: string.isRequired,
    messageText: string.isRequired,
    newTab: bool.isRequired,
    onClose: func.isRequired,
    url: string.isRequired,
    visible: bool.isRequired,
};

export default AnnouncementMessage;
