/* eslint-disable max-len */
const ActionTypes = {
    // ANALYTICS ACTIONS
    ANALYTICS_TRACK: 'ANALYTICS_TRACK',

    // DATA ACTIONS
    DATA_ANNOUNCEMENTS_ERROR: 'DATA_ANNOUNCEMENTS_ERROR',
    DATA_ANNOUNCEMENTS_FETCHING: 'DATA_ANNOUNCEMENTS_FETCHING',
    DATA_ANNOUNCEMENTS_RECEIVED: 'DATA_ANNOUNCEMENTS_RECEIVED',
    DATA_CURRENT_SNAPSHOT_PAGE_SET_RECEIVED: 'DATA_CURRENT_SNAPSHOT_PAGE_SET_RECEIVED',
    DATA_CURRENT_SNAPSHOT_PAGE_SET_REQUESTED: 'DATA_CURRENT_SNAPSHOT_PAGE_SET_REQUESTED',
    DATA_EXPORT_RESULTS_ERROR: 'DATA_EXPORT_RESULTS_ERROR',
    DATA_EXPORT_RESULTS_EXPORTING: 'DATA_EXPORT_RESULTS_EXPORTING',
    DATA_EXPORT_RESULTS_FINISHED: 'DATA_EXPORT_RESULTS_FINISHED',
    DATA_EXPORT_RESULTS_REQUESTED: 'DATA_EXPORT_RESULTS_REQUESTED',
    DATA_HISTORY_ERROR: 'DATA_HISTORY_ERROR',
    DATA_HISTORY_FETCHING: 'DATA_HISTORY_FETCHING',
    DATA_HISTORY_RECEIVED: 'DATA_HISTORY_RECEIVED',
    DATA_HISTORY_REQUESTED: 'DATA_HISTORY_REQUESTED',
    DATA_HISTORY_SKIPPED: 'DATA_HISTORY_SKIPPED',
    DATA_HISTORY_DELETE_REQUESTED: 'DATA_HISTORY_DELETE_REQUESTED',
    DATA_HISTORY_DELETE_RECEIVED: 'DATA_HISTORY_DELETE_RECEIVED',
    DATA_HISTORY_DELETE_ERROR: 'DATA_HISTORY_DELETE_ERROR',
    DATA_LOCATIONS_ERROR: 'DATA_LOCATIONS_ERROR',
    DATA_LOCATIONS_FETCHING: 'DATA_LOCATIONS_FETCHING',
    DATA_LOCATIONS_RECEIVED: 'DATA_LOCATIONS_RECEIVED',
    DATA_LOCATIONS_REQUESTED: 'DATA_LOCATIONS_REQUESTED',
    DATA_PARAMS_SET: 'DATA_PARAMS_SET',
    DATA_REHYDRATATION_COMPLETE: 'DATA_REHYDRATATION_COMPLETE',
    DATA_RESULTS_EMPTY_RECEIVED: 'DATA_RESULTS_EMPTY_RECEIVED',
    DATA_RESULTS_ERROR: 'DATA_RESULTS_ERROR',
    DATA_RESULTS_FETCHING: 'DATA_RESULTS_FETCHING',
    DATA_RESULTS_MORE_EMPTY_RECEIVED: 'DATA_RESULTS_MORE_EMPTY_RECEIVED',
    DATA_RESULTS_MORE_ERROR: 'DATA_RESULTS_MORE_ERROR',
    DATA_RESULTS_MORE_FETCHING: 'DATA_RESULTS_MORE_FETCHING',
    DATA_RESULTS_MORE_RECEIVED: 'DATA_RESULTS_MORE_RECEIVED',
    DATA_RESULTS_MORE_REQUESTED: 'DATA_RESULTS_MORE_REQUESTED',
    DATA_RESULTS_RESET_RECEIVED: 'DATA_RESULTS_RESET_RECEIVED',
    DATA_RESULTS_RESET_REQUESTED: 'DATA_RESULTS_RESET_REQUESTED',
    DATA_RESULTS_RECEIVED: 'DATA_RESULTS_RECEIVED',
    DATA_RESULTS_REQUESTED: 'DATA_RESULTS_REQUESTED',
    DATA_RESULTS_UNCACHED_REQUESTED: 'DATA_RESULTS_UNCACHED_REQUESTED',
    DATA_URL_DATA_ERROR: 'DATA_URL_DATA_ERROR',
    DATA_URL_DATA_FETCHING: 'DATA_URL_DATA_FETCHING',
    DATA_URL_DATA_RECEIVED: 'DATA_URL_DATA_RECEIVED',
    DATA_URL_DATA_REQUESTED: 'DATA_URL_DATA_REQUESTED',
    DATA_SNAPSHOT_IMAGE_REQUESTED: 'DATA_SNAPSHOT_IMAGE_REQUESTED',
    DATA_SNAPSHOT_IMAGE_FINISHED: 'DATA_SNAPSHOT_IMAGE_FINISHED',
    DATA_SNAPSHOT_IMAGE_ERROR: 'DATA_SNAPSHOT_IMAGE_ERROR',

    // UI ACTIONS
    UI_ALL_CLOSE: 'UI_ALL_CLOSE',

    // COMPARING BOX UI ACTIONS
    UI_COMPARING_BOX_CURRENT_VIEW_SET: 'UI_COMPARING_BOX_CURRENT_VIEW_SET',
    UI_COMPARING_BOX_DELTA_Y_SET: 'UI_COMPARING_BOX_DELTA_Y_SET',
    UI_COMPARING_BOX_SCROLL_LEFT_SET: 'UI_COMPARING_BOX_SCROLL_LEFT_SET',
    UI_COMPARING_BOX_URL_PROTOCOL_SET: 'UI_COMPARING_BOX_URL_PROTOCOL_SET',
    UI_COMPARING_BOX_URL_SET: 'UI_COMPARING_BOX_URL_SET',

    // UI DEFAULTS ACTIONS
    UI_DEFAULTS_ALL_SET: 'UI_DEFAULTS_ALL_SET',
    UI_DEFAULTS_KEYWORD_SET: 'UI_DEFAULTS_KEYWORD_SET',
    UI_DEFAULTS_LOCATION_SET: 'UI_DEFAULTS_LOCATION_SET',
    UI_DEFAULTS_PLATFORM_ID_SET: 'UI_DEFAULTS_PLATFORM_ID_SET',
    UI_DEFAULTS_SELECTED_METRICS_SET: 'UI_DEFAULTS_SELECTED_METRICS_SET',

    // DROPDOWNS UI ACTIONS
    UI_DROPDOWNS_ACTIONS_CLOSE: 'UI_DROPDOWNS_ACTIONS_CLOSE',
    UI_DROPDOWNS_ACTIONS_SHOW: 'UI_DROPDOWNS_ACTIONS_SHOW',
    UI_DROPDOWNS_HELP_CLOSE: 'UI_DROPDOWNS_HELP_CLOSE',
    UI_DROPDOWNS_HELP_SHOW: 'UI_DROPDOWNS_HELP_SHOW',

    // MESSAGES UI ACTIONS
    UI_MESSAGES_ACCESS_DENIED_CLOSE: 'UI_MESSAGES_ACCESS_DENIED_CLOSE',
    UI_MESSAGES_ACCESS_DENIED_SHOW: 'UI_MESSAGES_ACCESS_DENIED_SHOW',
    UI_MESSAGES_EXPORT_CLOSE: 'UI_MESSAGES_EXPORT_CLOSE',
    UI_MESSAGES_EXPORT_SHOW: 'UI_MESSAGES_EXPORT_SHOW',
    UI_MESSAGES_FAILURE_CLOSE: 'UI_MESSAGES_FAILURE_CLOSE',
    UI_MESSAGES_FAILURE_SHOW: 'UI_MESSAGES_FAILURE_SHOW',
    UI_MESSAGES_KD_INFO_CLOSE: 'UI_MESSAGES_KD_INFO_CLOSE',
    UI_MESSAGES_KD_INFO_SHOW: 'UI_MESSAGES_KD_INFO_SHOW',
    UI_MESSAGES_LOGGED_OUT_CLOSE: 'UI_MESSAGES_LOGGED_OUT_CLOSE',
    UI_MESSAGES_LOGGED_OUT_SHOW: 'UI_MESSAGES_LOGGED_OUT_SHOW',
    UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE: 'UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE',
    UI_MESSAGES_NEED_TO_SIGN_IN_SHOW: 'UI_MESSAGES_NEED_TO_SIGN_IN_SHOW',
    UI_MESSAGES_NO_CONNECTION_CLOSE: 'UI_MESSAGES_NO_CONNECTION_CLOSE',
    UI_MESSAGES_NO_CONNECTION_SHOW: 'UI_MESSAGES_NO_CONNECTION_SHOW',
    UI_MESSAGES_PRICING_CLOSE: 'UI_MESSAGES_PRICING_CLOSE',
    UI_MESSAGES_PRICING_SHOW: 'UI_MESSAGES_PRICING_SHOW',
    UI_MESSAGES_SERP_FEATURES_IMPACT_INFO_CLOSE: 'UI_MESSAGES_SERP_FEATURES_IMPACT_INFO_CLOSE',
    UI_MESSAGES_SERP_FEATURES_IMPACT_INFO_SHOW: 'UI_MESSAGES_SERP_FEATURES_IMPACT_INFO_SHOW',
    UI_MESSAGES_SERP_FEATURE_INFO_CLOSE: 'UI_MESSAGES_SERP_FEATURE_INFO_CLOSE',
    UI_MESSAGES_SERP_FEATURE_INFO_SHOW: 'UI_MESSAGES_SERP_FEATURE_INFO_SHOW',
    UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE: 'UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE',
    UI_MESSAGES_SHORTCUTS_SHOW: 'UI_MESSAGES_SHORTCUTS_SHOW',
    UI_MESSAGES_SHORTCUTS_CLOSE: 'UI_MESSAGES_SHORTCUTS_CLOSE',

    // METRICS UI ACTIONS
    UI_METRICS_FILTER_SET: 'UI_METRICS_FILTER_SET',
    UI_METRICS_MODAL_CLOSE: 'UI_METRICS_MODAL_CLOSE',
    UI_METRICS_MODAL_SHOW: 'UI_METRICS_MODAL_SHOW',

    // PANELS
    UI_PANELS_APP_CLOSE: 'UI_PANELS_APP_CLOSE',
    UI_PANELS_APP_SHOW: 'UI_PANELS_APP_SHOW',
    UI_PANELS_HISTORY_CLOSE: 'UI_PANELS_HISTORY_CLOSE',
    UI_PANELS_HISTORY_SHOW: 'UI_PANELS_HISTORY_SHOW',

    // MISC UI ACTIONS
    UI_MISC_COLOR_SCHEME_SET: 'UI_MISC_COLOR_SCHEME_SET',
    UI_MISC_DASHBOARD_TAB_SET: 'UI_MISC_DASHBOARD_TAB_SET',
    UI_MISC_LONGER_LOADING_NOTIFICATION_HIDE: 'UI_MISC_LONGER_LOADING_NOTIFICATION_HIDE',
    UI_MISC_LONGER_LOADING_NOTIFICATION_SHOW: 'UI_MISC_LONGER_LOADING_NOTIFICATION_SHOW',
    UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET: 'UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET',
    UI_MISC_NOTIFICATIONS_CLOSE_ALL: 'UI_MISC_NOTIFICATIONS_CLOSE_ALL',
    UI_MISC_NOTIFICATIONS_ERROR_SHOW: 'UI_MISC_NOTIFICATIONS_ERROR_SHOW',
    UI_MISC_NOTIFICATIONS_INFO_SHOW: 'UI_MISC_NOTIFICATIONS_INFO_SHOW',
    UI_MISC_ONLINE_STATUS_SET: 'UI_MISC_ONLINE_STATUS_SET',
    UI_MISC_RESULT_AND_COMPARING_TABLES_HORIZONTAL_SCROLL_SYNC_REQUESTED:
        'UI_MISC_RESULT_AND_COMPARING_TABLES_HORIZONTAL_SCROLL_SYNC_REQUESTED',

    // USER ACTIONS
    USER_CHECK_STATUS_ERROR: 'USER_CHECK_STATUS_ERROR',
    USER_CHECK_STATUS_SKIPPED: 'USER_CHECK_STATUS_SKIPPED',
    USER_DATA_ERROR: 'USER_DATA_ERROR',
    USER_DATA_FETCHING: 'USER_DATA_FETCHING',
    USER_DATA_RECEIVED: 'USER_DATA_RECEIVED',
    USER_LIMIT_DATA_ERROR: 'USER_LIMIT_DATA_ERROR',
    USER_LIMIT_DATA_FETCHING: 'USER_LIMIT_DATA_FETCHING',
    USER_LIMIT_DATA_RECEIVED: 'USER_LIMIT_DATA_RECEIVED',
    USER_LIMIT_DATA_REQUESTED: 'USER_LIMIT_DATA_REQUESTED',
    USER_LIMIT_DATA_SKIPPED: 'USER_LIMIT_DATA_SKIPPED',
    USER_LOGOUT_ERROR: 'USER_LOGOUT_ERROR',
    USER_LOGOUT_RECEIVED: 'USER_LOGOUT_RECEIVED',
    USER_LOGOUT_REQUESTED: 'USER_LOGOUT_REQUESTED',
    DATA_USER_AUTH_TOKENS_RECEIVED: 'DATA_USER_AUTH_TOKENS_RECEIVED',
    DATA_USER_DATA_SKIPPED: 'DATA_USER_DATA_SKIPPED',
    DATA_USER_SET_UNLEASH_SESSION: 'DATA_USER_SET_UNLEASH_SESSION',

    // THIRD PARTY ACTION TYPES
    ROUTER_LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
    ROUTER_NAVIGATION_REQUESTED: 'ROUTER_NAVIGATION_REQUESTED',
    ROUTER_AUTH_TOKENS_HIDE_REQUESTED: 'ROUTER_AUTH_TOKENS_HIDE_REQUESTED',
};

export default ActionTypes;
