export const PREVIOUS_SNAPSHOT_PAGE = 'PREVIOUS_SNAPSHOT_PAGE';
export const NEXT_SNAPSHOT_PAGE = 'NEXT_SNAPSHOT_PAGE';
export const TOGGLE_METRICS_MODAL = 'TOGGLE_METRICS_MODAL';
export const TOGGLE_EXPORT_MODAL = 'TOGGLE_EXPORT_MODAL';
export const FOCUS_COMPARE_INPUT = 'FOCUS_COMPARE_INPUT';
export const LOAD_MORE_RESULTS = 'LOAD_MORE_RESULTS';
export const TOGGLE_TABS = 'TOGGLE_TABS';
export const ARROW_UP = 'ARROW_UP';
export const ARROW_DOWN = 'ARROW_DOWN';
export const ENTER = 'ENTER';
export const CLOSE_ALL = 'CLOSE_ALL';
export const SHOW_SHORTCUTS_TOGGLE = 'SHOW_SHORTCUTS_TOGGLE';

export default {
    [TOGGLE_EXPORT_MODAL]: ['e'],
    [TOGGLE_TABS]: ['t'],
    [PREVIOUS_SNAPSHOT_PAGE]: ['left', 'p'],
    [NEXT_SNAPSHOT_PAGE]: ['right', 'n'],
    [TOGGLE_METRICS_MODAL]: ['m'],
    [FOCUS_COMPARE_INPUT]: ['c'],
    [LOAD_MORE_RESULTS]: ['n'],
    [ARROW_DOWN]: 'down',
    [ARROW_UP]: 'up',
    [ENTER]: 'enter',
    [SHOW_SHORTCUTS_TOGGLE]: ['shift+?'],
    [CLOSE_ALL]: 'esc',
};
