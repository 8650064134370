import { createSelector } from 'reselect';
import isNil from 'ramda/src/isNil';
import { FEATURE_TYPES } from 'constants/SerpFeatureTypes';

const fetchingMoreSelector = state => state.results.fetchingMore;

export const serpIdSelector = state => state.results.id;
export const ctrSelector = state => state.results.ctr;
export const resultsSelector = state => state.results.data;
export const fetchingSelector = state => state.results.fetching;
export const nextPageSelector = state => state.results.nextPage;
export const resultCountSelector = state => state.results.resultCount;
export const seoCompetitionSelector = state => state.results.seoCompetition;
export const serpFeaturesImpactSelector = state => state.results.serpFeaturesImpact;
export const serpSnapshotIdsSelector = state => state.results.serpSnapshotIds;
export const fetchedSerpsCountSelector = state => state.results.serpSnapshotIds?.length;

export const globalMetricsSelector = createSelector(
    [resultCountSelector, seoCompetitionSelector, serpFeaturesImpactSelector],
    (resultCount, seoCompetition, serpFeaturesImpact) => ({
        resultCount,
        seoCompetition,
        serpFeaturesImpact,
    }),
);

export const resultsLoadingSelector = createSelector(
    [fetchingSelector, fetchingMoreSelector],
    (fetching, fetchingMore) => fetching || fetchingMore,
);

export const serpFeaturesInResultsSelector = createSelector([resultsSelector], results => {
    const allTypes = results.map(result => result.type);
    const featureTypes = allTypes.filter(type => type !== FEATURE_TYPES.ORGANIC);
    const featuresAndCounts = {};

    featureTypes.reduce((resultObject, type) => {
        if (!isNil(resultObject[type])) {
            resultObject[type] += 1; // eslint-disable-line no-param-reassign
        } else {
            resultObject[type] = 1; // eslint-disable-line no-param-reassign
        }

        return resultObject;
    }, featuresAndCounts);

    return featuresAndCounts;
});
