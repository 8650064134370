import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    metrics: {
        filter: '',
        visibility: false,
    },
};

const modalsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_METRICS_MODAL_SHOW: {
            return update(state, {
                metrics: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_METRICS_MODAL_CLOSE: {
            return update(state, {
                metrics: {
                    visibility: {
                        $set: false,
                    },
                    filter: {
                        $set: '',
                    },
                },
            });
        }
        case ActionTypes.UI_METRICS_FILTER_SET: {
            return update(state, {
                metrics: {
                    filter: {
                        $set: action.payload,
                    },
                },
            });
        }
        case ActionTypes.UI_DEFAULTS_SELECTED_METRICS_RESET: {
            return update(state, {
                metrics: {
                    filter: {
                        $set: initialState.metrics.filter,
                    },
                },
            });
        }
        case ActionTypes.UI_ALL_CLOSE: {
            return update(state, {
                metrics: {
                    visibility: {
                        $set: false,
                    },
                    filter: {
                        $set: '',
                    },
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default modalsReducer;
