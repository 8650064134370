import React from 'react';
import { func, string } from 'prop-types';

function MetricsSearch(props) {
    const onChange = e => {
        e.preventDefault();
        return props.onMetricFilterChange(e.target.value);
    };

    return (
        <div className="uk-flex-item-none">
            <input
                className="mg-input is-small is-search"
                onChange={onChange}
                placeholder="e.g. moztrust"
                spellCheck="false"
                style={{ minWidth: '260px' }}
                type="text"
                value={props.metricFilter}
            />
        </div>
    );
}

MetricsSearch.propTypes = {
    metricFilter: string.isRequired,
    onMetricFilterChange: func.isRequired,
};

export default MetricsSearch;
