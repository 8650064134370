import Urls from 'mangools-commons/lib/constants/Urls';
import { FEATURE_TYPES, SUB_FEATURE_TYPES } from 'constants/SerpFeatureTypes';

const SERP_FEATURES_BLOG_POST_PATH = '28-google-rich-snippets-you-should-know-2018-guide-infographic';

export const FEATURES = {
    [FEATURE_TYPES.ADV_BOTTOM]: {
        id: 'f1',
        name: 'Google Ads Bottom',
        iconName: 'icon-serp-icon-01',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A block of Google Ads shown below the organic results.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#ad`,
    },
    [FEATURE_TYPES.ADV_TOP]: {
        id: 'f2',
        name: 'Google Ads Top',
        iconName: 'icon-serp-icon-01',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A block of Google Ads shown above the organic results.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#ad`,
    },
    [FEATURE_TYPES.ADS]: {
        id: 'f2',
        name: 'Google Ads Middle',
        iconName: 'icon-serp-icon-01',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A block of Google Ads shown between the organic results.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#ad`,
    },
    [FEATURE_TYPES.ANSWER_BOX]: {
        id: 'f3',
        name: 'Answer Box',
        iconName: 'icon-serp-icon-09',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            Answers the user's question directly, so usually it is not necessary to click on any organic result.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#answer-box`,
    },
    [FEATURE_TYPES.FEATURED_SNIPPET]: {
        id: 'f4',
        name: 'Featured Snippet',
        iconName: 'icon-serp-icon-08',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            An instant answer or definition of the query based on the content from the website
            in the first SERP. It includes the link to the website. Also known as position zero.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#featured-snippet`,
    },
    [FEATURE_TYPES.FLIGHTS]: {
        id: 'f5',
        name: 'Flights',
        iconName: 'icon-serp-icon-03',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A Google Flights search embedded into the SERP.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#flights`,
    },
    [FEATURE_TYPES.IMG_PACK]: {
        id: 'f6',
        name: 'Image Pack',
        iconName: 'icon-serp-icon-10',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A row of Google Images results related to the search query.
            It may appear anywhere in the organic results.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#image-pack`,
    },
    [FEATURE_TYPES.INDEPTH_ARTICLES]: {
        id: 'f7',
        name: 'In-depth Articles',
        iconName: 'icon-serp-icon-12',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A list of in-depth expert articles.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#link90`,
    },
    [FEATURE_TYPES.INSTALL_APP]: {
        id: 'f8',
        name: 'Install App',
        iconName: 'icon-serp-icon-14',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            An app box that redirects the user into the Google Play or App Store to download the app.
            If the app is already installed, it will open the content in the app.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#app-ranking`,
    },
    [FEATURE_TYPES.INSTALL_APP_CAROUSEL]: {
        id: 'f9',
        name: 'Install App (carousel)',
        iconName: 'icon-serp-icon-14',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            An app carousel that redirects the user into the Google Play or App Store to download the app.
            If the app is already installed, it will open the app.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#app-ranking`,
    },
    [FEATURE_TYPES.JOB_POSTING]: {
        id: 'f10',
        name: 'Job Posting',
        iconName: 'icon-serp-icon-15',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A block of job offers for a given search query.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#jobs`,
    },
    [FEATURE_TYPES.KNO_GRAPH]: {
        id: 'f11',
        name: 'Knowledge Graph',
        iconName: 'icon-serp-icon-04',
        showHorizontalPosition: true,
        showSnapshotPreview: true,
        description: `
            Offers detailed information about entities such as companies, famous persons,
            books, movies, songs, places, etc. It usually appears in the right panel on the
            desktop, however, it can be found also at the top of the main feed.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#knowledge-graph`,
    },
    [FEATURE_TYPES.MAP_PACK]: {
        id: 'f12',
        name: 'Map Pack',
        iconName: 'icon-serp-icon-05',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            Related local businesses displayed on the map. A map pack usually appears
            on the top of the results, however, it can be found lower in the feed too.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#map-pack`,
    },
    [FEATURE_TYPES.ORGANIC]: {
        id: 'f13',
        name: 'Organic Result',
        iconName: null,
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: null,
        blogUrl: null,
    },
    [FEATURE_TYPES.SHOP_RESULTS]: {
        id: 'f14',
        name: 'Shop Results',
        iconName: 'icon-serp-icon-02',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A block of sponsored products with images.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#shop`,
    },
    [FEATURE_TYPES.SIMILAR_ENTITIES]: {
        id: 'f15',
        name: 'Similar Entities',
        iconName: 'icon-serp-icon-22',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A list of entities similar to the original query.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#similar-entities`,
    },
    [FEATURE_TYPES.SIMILAR_ENTITIES2]: {
        id: 'f28',
        name: 'Similar Entities',
        iconName: 'icon-serp-icon-22',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A list of entities similar to the original query.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#similar-entities`,
    },
    [FEATURE_TYPES.SIMILAR_PLACES]: {
        id: 'f16',
        name: 'Similar Places',
        iconName: 'icon-serp-icon-07',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A list of places similar to the original query.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#similar-places`,
    },
    [FEATURE_TYPES.SIMILAR_QUERIES]: {
        id: 'f17',
        name: 'Similar Queries',
        iconName: 'icon-serp-icon-25',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            Related searches based on the previous queries of the users.
            They can be found at the bottom of the organic results in almost every SERP.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#related-searches`,
    },
    [FEATURE_TYPES.SIMILAR_QUESTIONS]: {
        id: 'f18',
        name: 'People also ask',
        iconName: 'icon-serp-icon-24',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A list of questions similar to the original query. Also known as "People also ask".
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#people-also-ask`,
    },
    [FEATURE_TYPES.SPECIFY_QUERY]: {
        id: 'f19',
        name: 'Specify Query',
        iconName: 'icon-serp-icon-23',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A specification of the query in case the original query is ambivalent and
            needs to be specified more closely.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#people-also-ask`,
    },
    [FEATURE_TYPES.SPELL_ERROR]: {
        id: 'f20',
        name: 'Spell Error',
        iconName: 'icon-serp-icon-26',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A correction of a misspelled keyword. SERP is showing results for corrected keyword instead of entered one".
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}`,
    },
    [FEATURE_TYPES.TOP_CAROUSEL]: {
        id: 'f21',
        name: 'Top Carousel',
        iconName: 'icon-serp-icon-21',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A carousel of related entities. It appears above the organic results, right below the search bar.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#top-carousel`,
    },
    [FEATURE_TYPES.TOP_STORIES]: {
        id: 'f22',
        name: 'Top Stories',
        iconName: 'icon-serp-icon-12',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A block of articles that may be displayed as a carousel with the top stories related to the search query.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#top-stories`,
    },
    [FEATURE_TYPES.TOP_STORIES_ROW]: {
        id: 'f23',
        name: 'Top Stories (row)',
        iconName: 'icon-serp-icon-12',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A list of articles displayed as separate rows with the top stories related to the search query.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#top-stories`,
    },
    [FEATURE_TYPES.VIDEO_BIG]: {
        id: 'f25',
        name: 'Video',
        iconName: 'icon-serp-icon-11',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A big, prominent video result above the organic results.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#video-big`,
    },
    [FEATURE_TYPES.VIDEO_PACK]: {
        id: 'f26',
        name: 'Video pack',
        iconName: 'icon-serp-icon-11',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A row of video results related to the search query. It may appear anywhere in the organic results.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#video-big`,
    },
    [FEATURE_TYPES.SUGGESTION]: {
        id: 'f27',
        name: 'Did you mean?',
        iconName: 'icon-serp-icon-26',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            Offers suggestion for your current query e.g. "Did you mean: mangools.com". SERP is showing results
            for entered keyword.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}`,
    },
};

export const SUB_FEATURES = {
    [SUB_FEATURE_TYPES.AD_FEATURE]: {
        id: 'sf1',
        name: 'Ad Feature',
        iconName: 'icon-serp-icon-01',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A call to action or additional info within an Google Ad.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}`,
    },
    [SUB_FEATURE_TYPES.BREADCRUMBS]: {
        id: 'sf2',
        name: 'Breadcrumbs',
        iconName: 'icon-serp-icon-18',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A breadcrumb replacement of the URL in an organic result.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#breadcrumbs`,
    },
    [SUB_FEATURE_TYPES.EVENT_LIST]: {
        id: 'sf3',
        name: 'Event List',
        iconName: 'icon-serp-icon-17',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A box with the upcoming events including the time and description.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#event`,
    },
    [SUB_FEATURE_TYPES.IMAGE_GALLERY]: {
        id: 'sf4',
        name: 'Image Gallery',
        iconName: 'icon-serp-icon-10',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            An image gallery within an organic result.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}`,
    },
    [SUB_FEATURE_TYPES.IMAGE_THUMBNAIL]: {
        id: 'sf5',
        name: 'Image Thumbnail',
        iconName: 'icon-serp-icon-10',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            An image thumbnail within an organic result.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}`,
    },
    [SUB_FEATURE_TYPES.ITEM]: {
        id: 'sf6',
        name: 'Item',
        iconName: null,
        showHorizontalPosition: false,
        showSnapshotPreview: false,
        description: null,
        blogUrl: null,
    },
    [SUB_FEATURE_TYPES.RATE]: {
        id: 'sf7',
        name: 'Star Rating',
        iconName: 'icon-serp-icon-16',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A star rating within an organic result, usually for reviews, products or recipes.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#review`,
    },
    [SUB_FEATURE_TYPES.SEARCH_BOX]: {
        id: 'sf8',
        name: 'Search Box',
        iconName: 'icon-serp-icon-20',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A search box for a specific page.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#searchbox`,
    },
    [SUB_FEATURE_TYPES.SIMILAR_RESULTS]: {
        id: 'sf9',
        name: 'Similar Results',
        iconName: 'icon-serp-icon-25',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A list of similar results within an organic results.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}`,
    },
    [SUB_FEATURE_TYPES.SITELINKS]: {
        id: 'sf10',
        name: 'Sitelinks',
        iconName: 'icon-serp-icon-19',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            Other links from the same domain.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#sitelinks`,
    },
    [SUB_FEATURE_TYPES.TOP_STORIES_FROM_WEBSITE]: {
        id: 'sf11',
        name: 'Top Stories (from website)',
        iconName: 'icon-serp-icon-12',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A top stories block within an organic result.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}#top-stories`,
    },
    [SUB_FEATURE_TYPES.TWEET]: {
        id: 'sf12',
        name: 'Tweet',
        iconName: 'icon-serp-icon-13',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A tweet or block of tweets within an organic result.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}`,
    },
    [SUB_FEATURE_TYPES.VIDEO_THUMBNAIL]: {
        id: 'sf13',
        name: 'Video Thumbnail',
        iconName: 'icon-serp-icon-11',
        showHorizontalPosition: false,
        showSnapshotPreview: true,
        description: `
            A video thumbnail within an organic result.
        `,
        blogUrl: `${Urls.MANGOOLS_BLOG_URL}/${SERP_FEATURES_BLOG_POST_PATH}`,
    },
    [SUB_FEATURE_TYPES.PRERENDER]: {
        id: 'sf14',
        name: 'Prerender',
        iconName: 'icon-serp-icon-25',
        showHorizontalPosition: false,
        showSnapshotPreview: false,
        description: `
            A <code>&lt;link rel=&quot;prerender&quot;/&gt;</code> added by Google.
            This usually means that <strong>users are more likely to click</strong>
            on this result and the <strong>actual CTR might be much higher</strong> than normally.
        `,
        blogUrl: null,
    },
};
